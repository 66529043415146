import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { AdminUserList } from './widgets/AdminUserList'

const AdminUsersOverview = (props) => {

  const dashboardBreadCrumbs: Array<PageLink> = [
    {
      title: 'Admin',
      path: '/admin',
      isSeparator: false,
      isActive: false,  
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        User verwalten
      </PageTitle>
      <div className="row">
        <div className="col-xl-12">
            <AdminUserList />
        </div>
      </div>
    </>
  )
}

export { AdminUsersOverview }
