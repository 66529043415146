import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const OAUTH_URL = process.env.REACT_APP_OAUTH_URL
const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
const OAUTH_CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`
export const LOGIN_URL = `${OAUTH_URL}/token`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  const data = {
    'client_id': OAUTH_CLIENT_ID,
    'client_secret': OAUTH_CLIENT_SECRET,
    'username': email,
    'password': password,
    'grant_type': 'password',
    'scope': ''
  };
  return axios.post<AuthModel>(LOGIN_URL, data, {headers: {'Content-Type': 'application/json'}})
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstname: firstname,
    lastname: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
