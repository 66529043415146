import { useState, useContext, createContext, FC, useEffect } from 'react'
import { WithChildren } from '../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import { HotelService } from '../services/hotel/_request'
import { useAsideSecondary } from './AsideSecondaryProvider'
import { HotelSecondaryNav } from '../pages/hotel/components/HotelSecondaryNav'

const initialHotel: any = {}
const HotelContext = createContext<any>({})

const HotelContextProvider: FC<WithChildren> = ({ children }) => {
  const { hotelId } = useParams()
  const [state, setState] = useState<any>(initialHotel)

  const {setContent} = useAsideSecondary();

  const updateState = (id: any) => {
    if (id === undefined)
      return
    if (id === 'undefined')
      return

    HotelService().getById(id).then((d) => {
      setState(d)
    })
  }
  useEffect(() => {
    setContent(<HotelSecondaryNav name={state.name || '\u00A0'}/>)
  },[state])

  const refresh = () => {
    updateState(hotelId);
  }
  
  useEffect(() => {
    updateState(hotelId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId])

  return (
    <HotelContext.Provider value={{ state, updateState, refresh }}>
      {children}
    </HotelContext.Provider>
  )

}

const useHotelContext = () => useContext(HotelContext)
export { HotelContextProvider, useHotelContext }
