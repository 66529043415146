
enum HotelRoleEnum {
    owner = 'Besitzer:in',
    admin = 'Administration',
    manager = 'Manager:in',
    editor = 'Bearbeiter:in',
    reader = 'Betrachter:in',
    webmaster = 'Webmaster:in',
}


export default HotelRoleEnum