/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import SystemRoleEnum from '../../../../../../enum/SystemRoleEnum';
import { UserService } from '../../../../../../services/users/_request';

type Props = {
    mode: string
    show: any
    item?: ID
    resetitem: any
    refresh: any
}

const initialValues = {
    firstname: '',
    lastname: '',
    role: 'user',
    email: '',
    password: '',
    password_confirm: '',
}

const CUUserModal: React.FC<Props> = (props) => {

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [oldmail,setOldMail] = useState<string>('');

    const { createUser, getByEmail, getById, updateUser } = UserService()

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Bitte einen Vornamen eingeben.'),
        lastname: Yup.string().required('Bitte einen Nachnamen eingeben.'),
        email: Yup.string().email('Bitte einen gültige E-Mail-Adresse eingeben.').required('Bitte einen E-Mail-Adresse eingeben.'),
        role: Yup.string().required('Bitte einen Endpoint Typ wählen.').oneOf(Object.keys(SystemRoleEnum)),
        password: Yup.string().when('role', {
            is: () => props.mode !== 'update',
            then: (schema) => schema.required('Bitte einen Passwort eingeben.').min(8, 'Das Passwort sollte mindestens 8 Zeichen beinhalten.'),
        }),
        password_confirm: Yup.string().when('role', {
            is: () => props.mode !== 'update',
            then: (schema) => schema.test('passwords-match', 'Passwörter stimmen nicht überein.', function (value) {
                return this.parent.password === value
            }),
        })
    }).shape({
        email_exists: Yup.boolean().test({
            name: 'email_exists',
            message: 'Diese E-Mail-Adresse existiert bereits.',
            exclusive: false,
            test: async (value, context) => {
                const before = Yup.string().email().required()
                const valid = before.isValidSync(context.parent.email)
                let result = true
                if(oldmail !== '' && context.parent.email === oldmail) {
                    return true
                }
                if (valid) {
                    result = await getByEmail(context.parent.email).then((d) => {
                        return d.email !== context.parent.email
                    }).catch(() => true)
                }
                return result
            },
        }),
    });

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            props.refresh()
            formik.resetForm()
            setOldMail('')
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            if (props.mode === 'create') {
                createUser(values).then((d) => {
                formikToast('Userdaten erfolgreich gespeichert.')
                }).catch((d) => {
                    formikToast('Es ist ein Fehler aufgetreten.', false)
                })
                return
            }
            if (props.mode === 'update') {
                updateUser(props.item, values).then((d) => {
                formikToast('Userdaten erfolgreich gespeichert.')
                }).catch((d) => {
                    console.log(d);
                    
                    formikToast('Es ist ein Fehler aufgetreten.', false)
                })
                return
            }
            
            formikToast('Es ist ein Fehler aufgetreten.', false)
        },
    })

    useEffect(() => {
        if (props.mode === 'update' && props.item !== undefined && props.item !== null) {
            getById(props.item).then((data) => {
                formik.setFieldValue('firstname', data.firstname)
                formik.setFieldValue('lastname', data.lastname)
                formik.setFieldValue('email', data.email)
                formik.setFieldValue('role', data.system_role)

                setOldMail(data.email)
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])
    useEffect(() => {
        if (props.mode === 'create' && props.show.state)
            setIsLoading(false)
    }, [props.mode, props.show.state])


    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>User {props.mode === 'create' ? 'erstellen' : 'bearbeiten'}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 pt-0'>Vorname</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Vorname'
                                    {...formik.getFieldProps('firstname')}
                                />
                                {formik.touched.firstname && formik.errors.firstname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.firstname}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6'>Nachname</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Nachname'
                                    {...formik.getFieldProps('lastname')}
                                />
                                {formik.touched.lastname && formik.errors.lastname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.lastname}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6'>E-Mail</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='E-Mail'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                                {formik.touched.email && formik.errors['email_exists'] && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors['email_exists']}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {props.mode !== 'update' && <>
                            <div className='row mb-3'>
                                <div className="col-12">
                                    <label className='col-form-label fw-bold fs-6'>Passwort</label>
                                    <input
                                        type='password'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='Passwort'
                                        {...formik.getFieldProps('password')}
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.password}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className="col-12">
                                    <label className='col-form-label fw-bold fs-6'>Passwort bestätigen</label>
                                    <input
                                        type='password'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='Passwort bestätigen'
                                        {...formik.getFieldProps('password_confirm')}
                                    />
                                    {formik.touched.password_confirm && formik.errors.password_confirm && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.password_confirm}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>}

                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 '>Rolle</label>
                            </div>
                        </div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='user'
                                    id='role_user'
                                    defaultChecked={formik.values.role === 'user'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_user'>
                                    <div className='fw-bolder text-gray-800'>{SystemRoleEnum.user}</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='sysadmin'
                                    id='role_sysadmin'
                                    defaultChecked={formik.values.role === 'sysadmin'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_sysadmin'>
                                    <div className='fw-bolder text-gray-800'>{SystemRoleEnum.sysadmin}</div>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className='modal-footer gap-2 justify-content-end'>

                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-success': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Speichern</span>
                                    </>
                                }
                            </button>
                        </div>

                    </div>

                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }

        </Modal >
    )
}

export { CUUserModal }
