/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useHotelContext } from '../../../../../providers/HotelContextProvider';
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { EndpointService } from '../../../../../services/endpoints/_request';
import EpType from '../../../../../enum/EpTypeEnum';

type Props = {
    mode: string
    show: any
    item?: ID
    resetitem: any
}

const initialValues = {
    name: '',
    epType: '',
    enabled: true,
    kognitiv: {
        token: '',
        user: '',
        hotelId: '',
    },
    bookingsuedtirol: {
        pass: '',
        user: '',
        endpoint: 'https://easychannel.it/mss/mss_service.php',
        source: '',
        hotelId: ''
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Bitte mindestens 6 Zeichen eingeben.').required('Bitte einen Namen eingeben.'),
    epType: Yup.string().required('Bitte einen Endpoint Typ wählen.').oneOf([EpType.kognitiv, EpType.bookingsuedtirol]),
    enabled: Yup.boolean(),
    kognitiv: Yup.array().when(['epType'], {
        is: (sck) => sck === EpType.kognitiv,
        then: () =>
            Yup.object().shape({
                user: Yup.string().required('Bitte den User angeben.'),
                token: Yup.string().required('Bitte den Token angeben.'),
                hotelId: Yup.string().required('Bitte die Hotel ID angeben.'),
            }),
        otherwise: () => Yup.mixed().nullable()
    }),
    bookingsuedtirol: Yup.array().when(['epType'], {
        is: (sck) => sck === EpType.bookingsuedtirol,
        then: () =>
            Yup.object().shape({
                user: Yup.string().required('Bitte den User angeben.'),
                pass: Yup.string().required('Bitte das Passwort angeben.'),
                endpoint: Yup.string().required('Bitte den Endpoint angeben.'),
                hotelId: Yup.string().required('Bitte die Hotel ID angeben.'),
                source: Yup.string().required('Bitte die Source ID angeben.'),
            }),
        otherwise: () => Yup.mixed().nullable()
    }),
});

const CUEndpointModal: React.FC<Props> = (props) => {

    const hotel = useHotelContext();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { createEndpoint, getById, updateEndpoint } = EndpointService();

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        hotel.refresh()
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            if (props.item !== null && props.mode === 'update') {
                updateEndpoint(props.item, values).then(({ message }) => {
                    formikToast(message)
                }).catch(({ message }) => {
                    formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
                });
                return
            }
            if (props.item === null && props.mode === 'create') {
                createEndpoint(hotel.state.hotelId, values).then(({ message }) => {
                    formikToast(message)
                }).catch(({ message }) => {
                    formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
                });
                return
            }
            formikToast('Es ist ein Fehler aufgetreten.', false)
        },
    })

    let renderKognitivInputs = () => {
        return <div className={clsx("kognitiv-form", {
            'd-none': formik.values.epType !== 'kognitiv'
        })}>

            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>Kognitiv Hotel ID</label>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Hotel ID'
                        {...formik.getFieldProps('kognitiv.hotelId')}
                    />
                    {formik.touched.kognitiv?.hotelId && formik.errors.kognitiv?.hotelId && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.kognitiv?.hotelId}</div>
                        </div>
                    )}
                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>Kognitiv User</label>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='User'
                        {...formik.getFieldProps('kognitiv.user')}
                    />
                    {formik.touched.kognitiv?.user && formik.errors.kognitiv?.user && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.kognitiv?.user}</div>
                        </div>
                    )}
                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>Kognitiv Token</label>
                    <input
                        type='password'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Token'
                        {...formik.getFieldProps('kognitiv.token')}
                    />
                    {formik.touched.kognitiv?.token && formik.errors.kognitiv?.token && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.kognitiv?.token}</div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    }

    let renderBsInputs = () => {
        return <div className={clsx("bookingsuedtirol-form", {
            'd-none': formik.values.epType !== 'bookingsuedtirol'
        })}>
            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>BookingSüdtirol Endpoint URL</label>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='URL'
                        {...formik.getFieldProps('bookingsuedtirol.endpoint')}
                    />
                    {formik.touched.bookingsuedtirol?.endpoint && formik.errors.bookingsuedtirol?.endpoint && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.bookingsuedtirol?.endpoint}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>BookingSüdtirol User</label>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='User'
                        {...formik.getFieldProps('bookingsuedtirol.user')}
                    />
                    {formik.touched.bookingsuedtirol?.user && formik.errors.bookingsuedtirol?.user && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.bookingsuedtirol?.user}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>BookingSüdtirol Passwort</label>
                    <input
                        type='password'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Passwort'
                        {...formik.getFieldProps('bookingsuedtirol.pass')}
                    />
                    {formik.touched.bookingsuedtirol?.pass && formik.errors.bookingsuedtirol?.pass && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.bookingsuedtirol?.pass}</div>
                        </div>
                    )}
                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>BookingSüdtirol Hotel ID</label>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Hotel ID'
                        {...formik.getFieldProps('bookingsuedtirol.hotelId')}
                    />
                    {formik.touched.bookingsuedtirol?.hotelId && formik.errors.bookingsuedtirol?.hotelId && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.bookingsuedtirol?.hotelId}</div>
                        </div>
                    )}
                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-12">
                    <label className='col-form-label fw-bold fs-6'>BookingSüdtirol Source ID</label>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Source ID'
                        {...formik.getFieldProps('bookingsuedtirol.source')}
                    />
                    {formik.touched.bookingsuedtirol?.source && formik.errors.bookingsuedtirol?.source && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.bookingsuedtirol?.source}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    }

    useEffect(() => {
        if (props.mode === 'update' && props.item !== undefined && props.item !== null) {
            getById(props.item).then((data) => {
                formik.setFieldValue('epType', data.type)
                formik.setFieldValue('name', data.name)
                formik.setFieldValue(`${data.type}`, data.data)
                formik.setFieldValue('enabled', data.enabled)
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])
    useEffect(() => {
        if (props.mode === 'create' && props.show.state)
            setIsLoading(false)
    }, [props.mode, props.show.state])


    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>Endpoint {props.mode === 'create' ? 'erstellen' : 'bearbeiten'}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 pt-0'>Name</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </div>
                                )}
                                <small className='text-muted d-block pt-2'>Idealerweise verwendest du hier den Betriebsnamen, oder eine eindeutige Kennung, welche dir später beim Suchen hilft.</small>
                            </div>
                        </div>
                        {props.mode !== 'update' && <>
                            <div className='row mb-3'>
                                <div className="col-12">
                                    <label className='col-form-label fw-bold fs-6 '>Endpoint Typ</label>
                                </div>
                            </div>
                            <div className='d-flex fv-row'>
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input me-3 cursor-pointer'
                                        {...formik.getFieldProps('epType')}
                                        name='epType'
                                        type='radio'
                                        value='kognitiv'
                                        id='lb_kognitiv'
                                    />
                                    <label className='form-check-label cursor-pointer' htmlFor='lb_kognitiv'>
                                        <div className='fw-bolder text-gray-800'>Kognitiv</div>
                                        <div className='text-gray-600'>
                                            Die Daten findest du im Channel-Manager unter <pre className='d-inline bg-secondary p-1'>Schnittstellen &gt; PMS</pre>.
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="separator separator-dashed my-5"></div>
                            <div className='d-flex fv-row'>
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input me-3 cursor-pointer'
                                        {...formik.getFieldProps('epType')}
                                        name='epType'
                                        type='radio'
                                        value='bookingsuedtirol'
                                        id='lb_bookingsuedtirol'
                                    />
                                    <label className='form-check-label cursor-pointer' htmlFor='lb_bookingsuedtirol'>
                                        <div className='fw-bolder text-gray-800'>BookingSüdtirol</div>
                                        <div className='text-gray-600'>
                                            Die Daten zur MSS solltest du i. d. R. von BookingSüdtirol direkt erhalten.
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </>}

                        {formik.values.epType === '' ? <div className="mb-0"></div> : <div className="mb-6"></div>}
                        {renderKognitivInputs()}
                        {renderBsInputs()}

                    </div>

                    <div className='modal-footer gap-2 justify-content-between'>
                        <div className='form-check form-switch form-switch-sm form-check-success form-check-solid'>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={formik.values.enabled}
                                {...formik.getFieldProps('enabled')}
                            />
                            <label className='form-check-label'>{formik.values.enabled ? 'Aktiv' : 'Inaktiv'}</label>
                        </div>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-success': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Speichern</span>
                                    </>
                                }
                            </button>
                        </div>

                    </div>

                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }

        </Modal >
    )
}

export { CUEndpointModal }
