/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useTopbarProvider } from '../../../../app/providers/TopbarProvider'

const Topbar: FC = () => {

  const topbar = useTopbarProvider();


  return <div className='d-flex flex-shrink-0'>
    {topbar.content}
  </div>

  
}

export { Topbar }

