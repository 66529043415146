import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle, useLayout } from '../../../_metronic/layout/core'

import { DashboardComponent } from './DashboardComponent'

const DashboardPage = () => {

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row'>
        {/* begin::Col */}
        <div className='col-12'>
          <DashboardComponent />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = (props) => {
  const intl = useIntl()
  const { hideAside } = useLayout()
  useEffect(() => {
    hideAside()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
