import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const HINODE_URL = `${API_URL}/hinodes`

const HinodesService = () => {

    const getAll = (query?: string): Promise<any> => {
        return axios
            .get(`${HINODE_URL}?${query}`)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const getById = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${HINODE_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    const createHinode = (data: any): Promise<any | undefined> => {
        return axios
            .post(`${HINODE_URL}`, data)
            .then((response: Response<any>) => response.data)
    }

    const updateHinode = (id: ID, data: any): Promise<any | undefined> => {
        return axios
            .put(`${HINODE_URL}/${id}`, data)
            .then((response: Response<any>) => response.data)
    }
    const deleteHinode = (id: ID): Promise<any | undefined> => {
        return axios
            .delete(`${HINODE_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }

    const getDefaultSettings = (): Promise<any> => {
        return axios
            .get(`${HINODE_URL}/defaultsettings`)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const updateDefaultSettings = (data: any): Promise<any> => {
        return axios
            .post(`${HINODE_URL}/defaultsettings`, data)
            .then((d: AxiosResponse<any>) => d.data)
    }
    const getYaml = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${HINODE_URL}/${id}/doco`)
            .then((response: Response<any>) => response.data)
    }

    const mapEndpointsToHinode = (items: any, id: ID): Promise<any | undefined> => {
        let data = {
            'endpoint': items.join(','),
            'hinode': id,
        };
        return axios
            .post(`${HINODE_URL}/map`, data)
            .then((response: Response<any>) => response.data)
    }
    return {
        getAll,
        getById,
        createHinode,
        updateHinode,
        deleteHinode,
        getDefaultSettings,
        updateDefaultSettings,
        getYaml,
        mapEndpointsToHinode
    }
}

export { HinodesService }