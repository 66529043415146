import { useEffect } from 'react'
import { PageTitle, useLayout } from '../../../_metronic/layout/core'

import { useHotelContext } from '../../providers/HotelContextProvider'
import { HotelInfoWidget } from './pages/HotelInfoWidget'
import { HotelInfoEndpointsWidget } from './pages/HotelInfoEndpointsWidget'

const HotelOverview = (props) => {
  const { showAside } = useLayout()

  const hotel = useHotelContext();

  useEffect(() => {
    showAside()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <PageTitle breadcrumbs={[{
        title: 'Hotels',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
      }]}>
        {hotel.state.name || 'Hotel Übersicht'}
      </PageTitle>
      <div className="row">
        <div className="col-xl-4">
          <HotelInfoWidget />
        </div>
        <div className="col-xl-8">
          <HotelInfoEndpointsWidget />
        </div>
      </div>
    </>
  )
}

export { HotelOverview }
