/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import { FC } from 'react'
//import { Dispatch, FC, SetStateAction } from 'react'
import { KTIcon } from '../../../helpers'
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHotelContext } from '../../../../app/providers/HotelContextProvider';

/* type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
} */

enum ActiveMode {
  BEGIN,
  CONTAINS,
  ENDS,
  EXACT,
}

const AsideNav: FC<any> = (props: any) => {

  const route = useParams()
  const { isSysadmin } = useAuth();
  const hotel = useHotelContext();

  const isActive = (link: string, mode: ActiveMode) => {
    if (route === undefined)
      return
    if (mode === ActiveMode.BEGIN)
      return route['*']?.startsWith(link, 0)

    if (mode === ActiveMode.EXACT)
      return (route['*'] !== undefined && route['*'] === link)
  }

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      {/* begin::Nav */}
      <ul className='nav flex-column gap-4' id='kt_aside_nav_tabs'>
        {/* begin::Nav item */}

        <li>
          {/* begin::Nav link */}
          <OverlayTrigger placement='right'
            overlay={<Tooltip >Dashboard</Tooltip>}
          >
            <Link className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
              { active: isActive('dashboard', ActiveMode.EXACT) }
            )} to='/dashboard'>
              <KTIcon iconName='element-11' className='fs-2x' />
            </Link>
          </OverlayTrigger>
          {/* end::Nav link */}
        </li>

        {/* begin::Nav Hinodemapper */}

        {isSysadmin() && <>
          <li>
            {/* begin::Nav link */}
            <OverlayTrigger placement='right'
              overlay={<Tooltip >Hinode Mapper</Tooltip>}
            >
              <Link className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
                { active: isActive('hinodes/', ActiveMode.BEGIN) }
              )} to='/hinodes'>
                <KTIcon iconName='data' className='fs-2x' />
              </Link>
              {/* end::Nav link */}
            </OverlayTrigger>
          </li>
        </>}
        {/* end::Nav Hinodemapper */}
        
        {/* begin::Nav Hotels */}

        {isActive('h/', ActiveMode.BEGIN) && <>
          <li>
            {/* begin::Nav link */}
            <OverlayTrigger placement='right'
              overlay={<Tooltip >Hotels</Tooltip>}
            >
              <Link className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
                { active: isActive('h/', ActiveMode.BEGIN) }
              )} to='/'>
                <KTIcon iconName='home-3' className='fs-2x' />
              </Link>
            </OverlayTrigger>
            {/* end::Nav link */}
          </li>
        </>}
        {/* end::Nav Hotels */}

      </ul>
      {/* end::Tabs */}
    </div>
  )
}

export { AsideNav }
