/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useHotelContext } from '../../../../../providers/HotelContextProvider';
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { EndpointService } from '../../../../../services/endpoints/_request';
import { PubleTokenService } from '../../../../../services/publetokens/_request';

type Props = {
    mode: string
    show: any
    item?: ID
    resetitem: any
}

const initialValues = {
    name: '',
    endpoint: '',
    active: true,
}

const validationSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Bitte mindestens 6 Zeichen eingeben.').required('Bitte einen Namen eingeben.'),
    endpoint: Yup.string().required('Bitte einen Endpoint wählen.'),
    active: Yup.boolean(),

});

const CUTokenModal: React.FC<Props> = (props) => {

    const hotel = useHotelContext();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [endpoints, setEndpoints] = useState<any>([]);

    const endpointService = EndpointService();
    const tokenService = PubleTokenService();

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        hotel.refresh()
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            if (props.item !== null && props.mode === 'update') {
                tokenService.updateToken(props.item, values).then(({ message }) => {
                    formikToast(message)
                }).catch(({ message }) => {
                    formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
                });
                return
            }
            if (props.item === null && props.mode === 'create') {
                tokenService.createToken(values).then(({ message }) => {
                    formikToast(message)
                }).catch((d) => {
                    console.log(d);

                    //formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
                });
                return
            }
            formikToast('Es ist ein Fehler aufgetreten.', false)
        },
    })

    useEffect(() => {
        if (props.mode === 'update' && props.item !== undefined && props.item !== null) {
            tokenService.getById(props.item).then((data) => {
                console.log(data);
                formik.setFieldValue('name', data.name)

                formik.setFieldValue('active', data.active === 1)
                formik.setFieldValue('endpoint', data.endpoint.id)
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])

    useEffect(() => {
        if (hotel.state.hotelId === undefined)
            return
        endpointService.getByHotel(hotel.state.hotelId).then((data) => {
            setEndpoints(data)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel.state])

    useEffect(() => {
        if (props.mode === 'create' && props.show.state)
            setIsLoading(false)
    }, [props.mode, props.show.state])


    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>Token {props.mode === 'create' ? 'erstellen' : 'bearbeiten'}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 pt-0'>Name</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </div>
                                )}
                                <small className='text-muted d-block pt-2'>Idealerweise verwendest du hier eine eindeutige Kennung (z. B. Website - Hotel XY), welche dir später beim Suchen hilft.</small>
                            </div>
                        </div>
                        {props.mode !== 'update' &&
                            <div className='row mb-3'>
                                <div className="col-12">
                                    <label className='col-form-label fw-bold fs-6 pt-0'>Endpoint wählen</label>
                                    <select className="form-select"
                                        aria-label="Endpoint"
                                        {...formik.getFieldProps('endpoint')}
                                    >
                                        <option value="">Endpoint wählen</option>
                                        {endpoints.length > 0 && endpoints.map((item, i) => {
                                            return <option key={i} value={item.id}>{item.name} - {item.uniqueId}</option>
                                        })}
                                    </select>
                                    {formik.touched.endpoint && formik.errors.endpoint && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.endpoint}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer gap-2 justify-content-between'>
                        <div className='form-check form-switch form-switch-sm form-check-success form-check-solid'>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={formik.values.active}
                                {...formik.getFieldProps('active')}
                            />
                            <label className='form-check-label'>{formik.values.active ? 'Aktiv' : 'Inaktiv'}</label>
                        </div>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-success': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Speichern</span>
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }

        </Modal >
    )
}

export { CUTokenModal }
