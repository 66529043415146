import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const ENDPOINTS_URL = `${API_URL}/endpoints`

const EndpointService = () => {

    const getAll = (query?: string): Promise<any> => {
        return axios
            .get(`${ENDPOINTS_URL}?${query}`)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const getById = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${ENDPOINTS_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    const getByHotel = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${ENDPOINTS_URL}/hotel/${id}`)
            .then((response: Response<any>) => response.data)
    }
    const createEndpoint = (hotelId: ID, data: any): Promise<any | undefined> => {
        return axios
            .post(`${ENDPOINTS_URL}/hotel/${hotelId}`, data)
            .then((response: Response<any>) => response.data)
    }

    const updateEndpoint = (id: ID, data: any): Promise<any | undefined> => {
        return axios
            .put(`${ENDPOINTS_URL}/${id}`, data)
            .then((response: Response<any>) => response.data)
    }
    const deleteEndpoint = (id: ID): Promise<any | undefined> => {
        return axios
            .delete(`${ENDPOINTS_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    
    return {
        getAll,
        getById,
        getByHotel,
        createEndpoint,
        updateEndpoint,
        deleteEndpoint
    }
}

export { EndpointService }