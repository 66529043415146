/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useTopbarProvider } from '../../../../../providers/TopbarProvider';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { HinodesService } from '../../../../../services/hinodes/_request';
import AdminHinodesTable from '../components/AdminHinodesTable';
import clsx from 'clsx';
import { CUHinodesModal } from '../modals/hinodes/CUHinodesModal';
import { DeleteHinodesModal } from '../modals/hinodes/DeleteHinodesModal';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DefaultHinodesSettingsModal } from '../modals/hinodes/DefaultHinodesSettingsModal';
import { YamlHinodeModal } from '../modals/hinodes/YamlHinodeModal';

const AdminHinodesList: React.FC<any> = (props) => {

    const [loading, setLoading] = useState<boolean>(true)

    const [users, setHinodes] = useState<any[]>([])
    const [allHinodes, setAllHinodes] = useState<any[]>([])

    const { getAll } = HinodesService();

    const [cumodalshow, setCuModalshow] = useState<boolean>(false)
    const [cumodalmode, setCuModalmode] = useState<string>('create')
    const [cumodalitem, setCuModalitem] = useState<ID>(null)

    const [delmodalshow, setDelModalshow] = useState<boolean>(false)
    const [delmodalitem, setDeleteModalitem] = useState<ID>(null)

    const [dsmodalshow, setDsModalshow] = useState<boolean>(false)

    const [docomodalshow, setDocoModalshow] = useState<boolean>(false)
    const [docomodalitem, setDocoeteModalitem] = useState<ID>(null)

    const topbar = useTopbarProvider();

    const columns = [
        {
            Header: "Hinode",
            accessor: "name",
            classNameHeader: 'w-400px',
        },
        {
            Header: "Zuletzt geshen",
            accessor: "last_seen",
            classNameHeader: 'w-400px',
            Cell: ({ cell }) => {
                if (cell.value === null)
                    return <div className={clsx('badge badge-lg mx-0 my-0 badge-light-danger')}>Nie</div>
                let val = moment(cell.value)
                let formatted = val.format('DD.MM.YYYY HH:mm:ss')
                let diff = moment.duration(moment().diff(val))
                return <div className={clsx('badge badge-lg mx-0 my-0 badge-secondary', {
                    'badge-light-danger': (diff.asMinutes() > 10)
                })}>{formatted}</div>
            }
        },
        {
            Header: "Environment",
            accessor: "env",
            classNameHeader: 'w-400px',
            Cell: ({ cell }) => <div className={clsx('badge badge-lg fw-bold mx-0 my-0', {
                "badge-light-primary text-success": cell.value === 'prod',
                "badge-light-warning text-warning": cell.value === 'dev'
            })}>{cell.value}</div>
        }, {
            Header: "Status",
            accessor: "active",
            classNameHeader: 'w-400px',
            Cell: ({ cell }) => <div className={clsx('badge badge-lg fw-bold mx-0 my-0', {
                "badge-light-primary text-success": cell.value,
                "badge-light-danger text-danger": !cell.value
            })}>{cell.value ? 'Aktiv' : 'Inaktiv'}</div>
        },
        {
            Header: "",
            accessor: "actions",
            classNameHeader: 'w-140px',
            classNameColumn: 'bg-info',

            Cell: ({ cell }) => {
                const item = cell.row.original
                const el = <div className="d-flex justify-content-end gap-2">
                    <OverlayTrigger
                        key={`hntoken-${item.id}`}
                        placement='left'
                        overlay={<Tooltip id={`hntoken-${item.id}`}>Token in Zwischenablage kopieren</Tooltip>}
                    >
                        <CopyToClipboard text={item.api_token} onCopy={() => toast.success('Token in Zwischenablage kopiert')}>
                            <div className="btn btn-sm btn-icon btn-secondary">
                                <KTIcon iconName='key' className='fs-6' />
                            </div>
                        </CopyToClipboard>
                    </OverlayTrigger>
                    <OverlayTrigger
                        key={`doco-${item.id}`}
                        placement='top'
                        overlay={<Tooltip id={`doco-${item.id}`}>Docker Compose YAML</Tooltip>}
                    >
                        <div className="btn btn-sm btn-icon btn-secondary" onClick={() => yamlModal(item.id)}>
                            <KTIcon iconName='information-2' className='fs-6' />
                        </div>
                    </OverlayTrigger>
                    <div className="btn btn-sm btn-icon btn-secondary" onClick={() => updateModal(item.id)}>
                        <KTIcon iconName='pencil' className='fs-6' />
                    </div>
                    <div className="btn btn-sm btn-icon btn-light-danger" onClick={() => deleteModal(item.id)}>
                        <KTIcon iconName='trash' className='fs-6' />
                    </div>
                </div>

                return el
            }
        },
    ]
    const handleChange = (e) => {
        let { value } = e.target
        value = value.toLowerCase()
        if (value.length >= 2) {
            setHinodes(allHinodes.filter((d) => d.name.toLowerCase().includes(value)))
        } else {
            setHinodes(allHinodes)
        }
    }
    const createModal = () => {
        setCuModalshow(true)
        setCuModalmode('create')
    }
    const updateModal = (item) => {
        setCuModalshow(true)
        setCuModalmode('update')
        setCuModalitem(item)

    }
    const deleteModal = (item) => {
        setDelModalshow(true)
        setDeleteModalitem(item)
    }
    const yamlModal = (item) => {
        setDocoModalshow(true)
        setDocoeteModalitem(item)
    }

    const defaultSettingsModal = () => {
        setDsModalshow(true)
    }
    const resetitem = () => {
        setCuModalitem(null)
        setDeleteModalitem(null)
        setDocoeteModalitem(null)
    }
    const refresh = () => {
        getAll().then((d) => {
            setTimeout(() => {
                d = d.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                setHinodes(d)
                setAllHinodes(d)
                setLoading(false)
            }, 500);
        })
    }
    const setTopbarContent = () => {
        topbar.setContent(
            <div className='d-flex ms-3 gap-4'>
                <div
                    className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                    onClick={() => defaultSettingsModal()}
                >
                    <KTIcon iconName='setting' className='fs-2 text-inverse me-0 me-md-2' />
                    <span className='d-none d-md-inline'>Standard Konfiguration</span>
                </div>

                <div
                    className='btn  btn-flex flex-center btn-primary w-40px w-md-auto h-40px px-0 px-md-6'
                    onClick={() => createModal()}
                >
                    <KTIcon iconName='plus-square' className='fs-2 text-inverse me-0 me-md-2' />
                    <span className='d-none d-md-inline'>Hinode erstellen</span>
                </div>
            </div>
        )
    }
    const nodata = () => {
        return <div className='card card-dashed bg-transparent'>
            <div className="card-body d-flex flex-column  align-items-center justify-content-center py-10">
                <KTIcon iconName='question-2' className='fs-5x text-inverse mb-6' />
                <div className='text-center fs-2 text-muted mb-4'>0 Hinodes</div>
                <div className='text-center text-muted'>Aktuell sind keine Hinodes vorhanden.<br /> Du kannst neue Hinodes über den Button oben rechts erstellen.</div>
            </div>
        </div>
    }
    useEffect(() => {
        setTopbarContent()

        refresh()

        return () => topbar.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!cumodalshow)
            setCuModalitem(null)
    }, [cumodalshow])

    return (
        <>
            {loading &&
                <div className="d-flex justify-content-center py-2">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }
            {(!loading && allHinodes.length > 0) &&
                <div className="row g-8">

                    <div className='col-lg-12'>
                        <div className="card">
                            <div className="card-header py-6 px-6 d-flex">
                                <input
                                    placeholder='Suche'
                                    type='text'
                                    name='search'
                                    className={clsx('form-control bg-transparent')}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="card-body pt-4">

                                <div className='table-responsive'>
                                    <AdminHinodesTable columns={columns} data={users} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
            {(!loading && allHinodes.length <= 0) && nodata()}

            <CUHinodesModal mode={cumodalmode} refresh={refresh} show={{ state: cumodalshow, set: setCuModalshow }} item={cumodalitem} resetitem={resetitem} />
            <DeleteHinodesModal show={{ state: delmodalshow, set: setDelModalshow }} refresh={refresh} item={delmodalitem} resetitem={resetitem} />
            <DefaultHinodesSettingsModal show={{ state: dsmodalshow, set: setDsModalshow }} />

            <YamlHinodeModal show={{ state: docomodalshow, set: setDocoModalshow }} item={docomodalitem} resetitem={resetitem} />

        </>
    )
}

export { AdminHinodesList }
