/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useHotelContext } from '../../../../../providers/HotelContextProvider';
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import { UserService } from '../../../../../services/users/_request';
import { HotelService } from '../../../../../services/hotel/_request';

type Props = {
    show: any
    item?: ID
    resetitem: any,
    refresh: any
}

const initialValues = {
}
const DeleteUserModal: React.FC<Props> = (props) => {

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [item, setItem] = useState<any>(null);

    const { getById } = UserService();
    const { removeUser } = HotelService();
    const hotel = useHotelContext();

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        props.refresh()
        close()
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            removeUser(hotel.state.hotelId, item.email).then(({ message }) => {
                formikToast(message)
            }).catch(({ message }) => {
                formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
            });
        },
    })
    useEffect(() => {
        if (props.item !== undefined && props.item !== null) {
            getById(props.item).then((data) => {
                setItem(data)
                setIsLoading(false)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])
    
    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>User entfernen?</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>
                        <div className='row mb-3'>
                            <div className="col-12">
                                Bist du sicher, dass du diesen User entfernen möchtest? <br />Du kannst die Person jederzeit wieder hinzufügen.
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer gap-2'>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-danger': !isSending
                                })}

                                disabled={isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='trash-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Löschen</span>
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }
        </Modal >
    )
}

export { DeleteUserModal }
