import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const TOKENS_URL = `${API_URL}/publetokens`

const PubleTokenService = () => {

    const getAll = (query?: string): Promise<any> => {
        return axios
            .get(`${TOKENS_URL}?${query}`)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const getById = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${TOKENS_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    const getByHotel = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${TOKENS_URL}/hotel/${id}`)
            .then((response: Response<any>) => response.data)
    }
    const createToken = (data: any): Promise<any | undefined> => {
        return axios
            .post(`${TOKENS_URL}`, data)
            .then((response: Response<any>) => response.data)
    }

    const updateToken = (id: ID, data: any): Promise<any | undefined> => {
        return axios
            .put(`${TOKENS_URL}/${id}`, data)
            .then((response: Response<any>) => response.data)
    }
    const deleteToken = (id: ID): Promise<any | undefined> => {
        return axios
            .delete(`${TOKENS_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    return {
        getAll,
        getById,
        getByHotel,
        createToken,
        updateToken,
        deleteToken
    }
}

export { PubleTokenService }