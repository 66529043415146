import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const HOTEL_URL = `${API_URL}/hotel`

const HotelService = () => {

    const getAll = (query?: string): Promise<any> => {
        return axios
            .get(`${HOTEL_URL}?${query}`)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const getById = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${HOTEL_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }

    const createHotel = (data: any): Promise<any | undefined> => {
        return axios
            .post(`${HOTEL_URL}`, data)
            .then((response: Response<any>) => response.data)
    }

    const updateHotel = (id: ID, data:any): Promise<any | undefined> => {
        return axios
            .put(`${HOTEL_URL}/${id}`, data)
            .then((response: Response<any>) => response.data)
    }
    const getPermittedUsers = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${HOTEL_URL}/${id}/users`)
            .then((response: Response<any>) => response.data)
    }
    const addUser = (id: ID, data: any): Promise<any | undefined> => {
        return axios
            .post(`${HOTEL_URL}/${id}/adduser`, data)
            .then((response: Response<any>) => response.data)
    }
    const removeUser = (id: ID, email: string): Promise<any | undefined> => {
        return axios
            .post(`${HOTEL_URL}/${id}/removeuser`, {email: email})
            .then((response: Response<any>) => response.data)
    }

    const deleteHotel = (id: ID): Promise<any | undefined> => {
        return axios
            .delete(`${HOTEL_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    return {
        getAll,
        getById,
        createHotel,
        updateHotel,
        deleteHotel,
        getPermittedUsers,
        addUser,
        removeUser,
    }
}

export {HotelService}