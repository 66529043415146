/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { ID, KTIcon } from '../../../../_metronic/helpers'
import { useHotelContext } from '../../../providers/HotelContextProvider'
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useTopbarProvider } from '../../../providers/TopbarProvider';
import { CUHotelModal } from '../components/modals/hotel/CUHotelModal';
import { DeleteHotelModal } from '../components/modals/hotel/DeleteHotelModal';
import { useAuth } from '../../../modules/auth';


const HotelInfoWidget: React.FC = () => {

    const { isSysadmin } = useAuth()

    const hotel = useHotelContext();
    const topbar = useTopbarProvider();
    const [cumodalshow, setCuModalshow] = useState<boolean>(false)

    const [delmodalshow, setDelModalshow] = useState<boolean>(false)
    const [delmodalitem, setDelModalitem] = useState<ID>(null)

    const updateModal = () => {
        setCuModalshow(true)
    }

    const deleteModal = () => {
        setDelModalshow(true)
    }

    const setTopbarContent = () => {
        topbar.setContent(
            <div className='d-flex ms-3 gap-4'>
                <div
                    className='btn  btn-flex flex-center btn-primary w-40px w-md-auto h-40px px-0 px-md-6'
                    onClick={() => updateModal()}
                >
                    <KTIcon iconName='setting' className='fs-2 text-inverse me-0 me-md-2' />
                    <span className='d-none d-md-inline'>Hotel bearbeiten</span>
                </div>
                {isSysadmin() &&
                    <div className="btn btn-icon btn-flex flex-center btn-danger h-40px w-40px" onClick={() => deleteModal()}>
                        <KTIcon iconName='trash' className='fs-6' />
                    </div>
                }
            </div>
        )
    }

    useEffect(() => {
        setTopbarContent()
        return () => {
            topbar.reset()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDelModalitem(hotel.state.hotelId)
        return () => {
            setDelModalitem(null)
        }
    }, [hotel.state])


    return (
        <>
            <div className={`card `}>
                {/* begin::Body */}
                <div className='card-body pb-0'>
                    {/* begin::Header */}
                    <div className='d-flex justify-content-between p-0 mb-5'>
                        {/* begin::User */}

                        {/* begin::Info */}
                        <div className='d-flex flex-column'>
                            <span className='text-gray-800 fs-6 fw-bold mb-2'>
                                Allgemeine Informationen
                            </span>

                            <div className='d-flex flex-row'>
                                <div className=''>Hotel ID:&nbsp;</div>
                                <CopyToClipboard text={hotel.state.hotelId}
                                    onCopy={() => toast.success('Hotel ID in Zwischenablage kopiert')}>
                                    <div className='badge bg-gray-200 text-gray-800 fw-bold my-0 cursor-pointer'>{hotel.state.hotelId}</div>
                                </CopyToClipboard>
                            </div>

                            {/* end::Info */}
                        </div>
                        {/* end::User */}

                        {/* begin::Menu
                    <div className='my-0'>
                        <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='category' className='fs-2' />
                        </button>
                    </div>
                    {/* end::Menu */}
                    </div>
                    {/* end::Header */}
                    <div className='separator mb-8'></div>
                    <div className="table-responsive mb-6">
                        <table className="align-middle gs-0 gy-3">
                            <thead>
                                <tr>
                                    <th className="p-0 min-w-150px"></th>
                                    <th className="p-0 min-w-150px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td className="py-1">Name</td><td>{hotel.state.name}</td></tr>
                                <tr><td className="py-1">Addresse</td><td>{hotel.state.address}</td></tr>
                                <tr><td className="py-1">PLZ / Ort</td><td>{hotel.state.zip} / {hotel.state.city}</td></tr>
                                <tr><td className="py-1">Land</td><td>{hotel.state.country_readable}</td></tr>
                                <tr><td className="py-1">Telefon</td><td>{hotel.state.phone}</td></tr>
                                <tr><td className="py-1">Email</td><td>{hotel.state.email}</td></tr>
                                <tr><td className="py-1">Steuer Nr.</td><td>{hotel.state.vat_id}</td></tr>
                                <tr><td className="py-1">UID</td><td>{hotel.state.vat_uid}</td></tr>
                                <tr><td className="py-1">Firmenbuch Nr.</td><td>{hotel.state.crn_id}</td></tr>
                                <tr><td className="py-1">Firmenbuch Gericht</td><td>{hotel.state.crn_court}</td></tr>
                                <tr><td className="py-1">IBAN</td><td>{hotel.state.iban}</td></tr>
                                <tr><td className="py-1">BIC</td><td>{hotel.state.bic}</td></tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/* end::Body */}
            </div>
            <CUHotelModal mode="update" show={{ state: cumodalshow, set: setCuModalshow }} item={hotel.state.hotelId} refresh={hotel.refresh} />
            <DeleteHotelModal show={{ state: delmodalshow, set: setDelModalshow }} item={delmodalitem} />

        </>
    )
}

export { HotelInfoWidget }
