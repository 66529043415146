/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { HotelService } from '../../../../../services/hotel/_request';
import { useNavigate } from 'react-router-dom';

type Props = {
    show: any
    item?: ID
}

const initialValues = {
    check: '',
}
const DeleteHotelModal: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [item, setItem] = useState<any>(null);

    const { getById, deleteHotel } = HotelService();

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
            setTimeout(() => {
                navigate("/dashboard");
            }, 300);
        } else {
            toast.error(message)
        }
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            check: Yup.string().required('Bitte die Hotel ID eingeben.').test('same', 'Bitte die richtige Hotel ID eingeben.', value => value === item?.hotelId)
        }),
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            deleteHotel(props.item).then(({ message }) => {
                formikToast(message)
            }).catch(({ message }) => {
                formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
            });
        },
    })
    useEffect(() => {
        if (props.item !== undefined && props.item !== null && props.show.state) {
            getById(props.item).then((data) => {
                setItem(data)
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item, props.show.state])
    useEffect(() => {
        setTimeout(() => formik.validateForm(), 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show.state])
    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>Hotel entfernen?</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <div className="alert alert-danger">Achtung: das Entfernen eines Hotels löscht auch alle damit verbundenen Daten. Dies kann (bewusst) nicht zurückgerollt werden und bedarf eine vollständige Neueinrichtung. <b>Bist du dir dabei sicher?</b></div>
                                <div className="alert alert-dark">Zur Bestätigung, bitte die Hotel ID <code>{item?.hotelId}</code> im nachfolgenden Feld eingeben.</div>
                                <label className='col-form-label fw-bold fs-6 pt-0mb-10'>Hotel ID</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Hotel ID'
                                    {...formik.getFieldProps('check')}
                                />
                                {formik.touched.check && formik.errors.check && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.check}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer gap-2'>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-danger': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='trash-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Löschen</span>
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }
        </Modal >
    )
}

export { DeleteHotelModal }
