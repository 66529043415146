import { useState, useContext, createContext, FC } from 'react'
import { WithChildren } from '../../_metronic/helpers'

const initialContent: any = null
const TopbarProvider = createContext<any>({})

const TopbarProviderProvider: FC<WithChildren> = ({ children }) => {
  const [content, setContent] = useState<any>(initialContent)

  const reset = () => {
    setContent(null)
  }
  
  return (
    <TopbarProvider.Provider value={{ reset, content, setContent }}>
      {children}
    </TopbarProvider.Provider>
  )

}

const useTopbarProvider = () => useContext(TopbarProvider)
export { TopbarProviderProvider, useTopbarProvider }
