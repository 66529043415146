/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../../_metronic/helpers';
import { HinodesService } from '../../../../../../services/hinodes/_request';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism';
import yaml from 'react-syntax-highlighter/dist/esm/languages/prism/yaml';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';

SyntaxHighlighter.registerLanguage('yaml', yaml);
SyntaxHighlighter.registerLanguage('bash', bash);

type Props = {
    show: any
    item?: ID
    resetitem: any
}

const YamlHinodeModal: React.FC<Props> = (props) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [item, setItem] = useState<any>(null);

    const { getYaml } = HinodesService();

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    useEffect(() => {
        if (props.item !== undefined && props.item !== null) {
            getYaml(props.item).then((data) => {
                setItem(data)
                setIsLoading(false)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])
    useEffect(() => {

    }, [props.show.state])
    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>Docker Compose YAML Vorlage</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <>
                    <div className='modal-body'>
                        <SyntaxHighlighter language="yaml" style={prism}>
                            {item}
                        </SyntaxHighlighter>
                        <div className='mt-5'>Bitte beachte den Log Pfad. Dieser ist nicht zwingend und du kannst ihn natürlich anpassen.</div>
                        <div className='mt-5 mb-6'>Stelle sicher, dass bereits ein Docker Bridge-Network eingerichtet ist:</div>
                        <SyntaxHighlighter language="bash" style={prism}>
                            docker network ls
                        </SyntaxHighlighter>
                        <div className='mt-5 mb-6'>Falls nicht, führe folgendes aus:</div>
                        <SyntaxHighlighter language="bash" style={prism}>
                            docker network create -d bridge hinode-network
                        </SyntaxHighlighter>

                    </div>
                    <div className='modal-footer gap-2'>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Schließen</span>
                            </span>

                        </div>
                    </div>
                </>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }
        </Modal >
    )
}

export { YamlHinodeModal }
