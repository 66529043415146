import Avatar from 'react-avatar'
import { KTIcon } from '../../../helpers'
//import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks } from '../../../partials'
import { HeaderUserMenu } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

const AsideFooter = () => {
  const { currentUser, isSysadmin } = useAuth()

  return (
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    >

      <ul className='nav flex-column mb-8'>
        {isSysadmin() &&
          <li>
            <Link className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',

            )} to='/admin'>
              <KTIcon iconName='setting' className='fs-2x' />
            </Link>
          </li>
        }
      </ul>


      {/* begin::User */}
      <div className='d-flex align-items-center mb-10' id='kt_header_user_menu_toggle'>
        {/* begin::Menu wrapper */}
        <div
          className='cursor-pointer symbol'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
        >
          <Avatar name={currentUser?.name} size="40" round="0.475rem" textSizeRatio={3} color="var(--bs-text-muted)" />
        </div>
        {/* end::Menu wrapper */}
        <HeaderUserMenu />
      </div>
      {/* end::User */}
    </div>
  )
}

export { AsideFooter }
