/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { HotelService } from '../../../../../services/hotel/_request';
import HotelFieldNamesEnum from '../../../../../enum/HotelFieldNamesEnum';
import * as i18nIsoCountries from 'i18n-iso-countries';

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));

type Props = {
    mode: string
    show: any
    item?: ID,
    refresh: any
}

const initialValues = {
    name: '',
    address: '',
    zip: '',
    city: '',
    country: 'AT',
    phone: '',
    email: '',
    vat_id: '',
    vat_uid: '',
    crn_id: '',
    crn_court: '',
    iban: '',
    bic: '',
    lat: '',
    lng: '',
}

const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Bitte mindestens 3 Zeichen eingeben.').required('Bitte einen Namen eingeben.'),
    address: Yup.string().required('Bitte eine Adresse eingeben.'),
    zip: Yup.string().required('Bitte eine PLZ eingeben.'),
    city: Yup.string().required('Bitte einen Ort eingeben.'),
    country: Yup.string().required('Bitte ein Land wählen.'),
    phone: Yup.string().required('Bitte einen Telefonnummer eingeben.'),
    email: Yup.string().required('Bitte einen E-Mail eingeben.'),
    vat_id: Yup.string().nullable(),
    vat_uid: Yup.string().nullable(),
    crn_id: Yup.string().nullable(),
    crn_court: Yup.string().nullable(),
    iban: Yup.string().nullable(),
    bic: Yup.string().nullable(),
    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
});

const CUHotelModal: React.FC<Props> = (props) => {

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { createHotel, getById, updateHotel } = HotelService();

    const countries = useMemo(() => i18nIsoCountries.getNames('de'), [])

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        props.refresh(true)
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            if (props.item !== null && props.mode === 'update') {
                updateHotel(props.item, values).then(({ message }) => {
                    formikToast(message)
                })
                .catch(({ message }) => {
                    formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
                });
                return
            }
            if (props.item === null && props.mode === 'create') {
                createHotel(values).then(({ message }) => {
                    formikToast(message)
                })
                .catch(({ message }) => {
                    formikToast('Es ist ein Fehler aufgetreten. \n' + message, false)
                });
                return
            }
            formikToast('Es ist ein Fehler aufgetreten.', false)
        },
    })

    const generateField = (index) => {
        switch (index) {
            case 'country':
                return <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formik.getFieldProps('country')}
                >
                    {Object.keys(countries).map((i) => <option value={i} key={i}>{countries[i]}</option>)}
                </select>

            default:
                return <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder={HotelFieldNamesEnum[index]}
                    {...formik.getFieldProps(index)}
                />
        }
    }

    useEffect(() => {
        if (props.mode === 'update' && props.item !== undefined && props.item !== null && props.show.state) {
            getById(props.item).then((data) => {
                Object.keys(HotelFieldNamesEnum).forEach((item) => formik.setFieldValue(item, data[item] === null ? '' : data[item]))
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item, props.show.state])
    useEffect(() => {
        if (props.mode === 'create' && props.show.state)
            setIsLoading(false)
    }, [props.mode, props.show.state])


    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>Hotel {props.mode === 'create' ? 'erstellen' : 'bearbeiten'}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>

                        {Object.keys(HotelFieldNamesEnum).map((index) =>
                            <div className='row mb-3' key={index}>
                                <div className="col-12">
                                    <label className='col-form-label fw-bold fs-6 pt-0'>{HotelFieldNamesEnum[index]}</label>
                                    {generateField(index)}
                                    {formik.touched[index] && formik.errors[index] && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors[index]}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}


                    </div>

                    <div className='modal-footer gap-2 justify-content-end'>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-success': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Speichern</span>
                                    </>
                                }
                            </button>
                        </div>

                    </div>

                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }

        </Modal >
    )
}

export { CUHotelModal }
