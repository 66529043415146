/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { UserService } from '../../../../../services/users/_request';
import HotelRoleEnum from '../../../../../enum/HotelRoleEnum';
import { HotelService } from '../../../../../services/hotel/_request';
import { useHotelContext } from '../../../../../providers/HotelContextProvider';
import { useAuth } from '../../../../../modules/auth';

type Props = {
    mode: string
    show: any
    item?: ID
    resetitem: any
    refresh: any
}

const initialValues = {
    role: 'reader',
    email: '',
}

const CUUserModal: React.FC<Props> = (props) => {

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const hotel = useHotelContext();
    const { currentUser } = useAuth()

    const { getByEmail, getById } = UserService()
    const { addUser } = HotelService()

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Bitte einen gültige E-Mail-Adresse eingeben.').required('Bitte einen E-Mail-Adresse eingeben.'),
        role: Yup.string().required('Bitte einen Endpoint Typ wählen.').oneOf(Object.keys(HotelRoleEnum)),
    }).shape({
        email_not_me: Yup.boolean().test({
            name: 'email_not_me',
            message: 'Aus Sicherheitsgründen, kannst du deine eigenen Einstellungen nicht ändern.',
            exclusive: true,
            test: async (value, context) => {
                return !(context.parent.email === currentUser?.email)
            },
        }),
        email_exists: Yup.boolean().test({
            name: 'email_exists',
            message: 'Diese E-Mail-Adresse existiert nicht im System.',
            exclusive: false,
            test: async (value, context) => {
                const before = Yup.string().email().required()
                const valid = before.isValidSync(context.parent.email_not_me)
                let result = false
                if (valid) {
                    result = await getByEmail(context.parent.email).then((d) => {
                        return d.email === context.parent.email
                    }).catch(() => false)
                } else {
                    return true
                }
                return result
            },
        }),
    });

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            props.refresh()
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)

            addUser(hotel.state.hotelId, values).then((d) => {
                formikToast('Einstellungen erfolgreich gespeichert.')
            }).catch((d) => {
                formikToast('Es ist ein Fehler aufgetreten.', false)
            })
            return
        },
    })

    useEffect(() => {
        if (props.mode === 'update' && props.item !== undefined && props.item !== null) {
            getById(props.item).then((data) => {
                formik.setFieldValue('email', data.email)
                let rolestring = `hotel.${hotel.state.hotelId}.`
                let role = data.user_roles.filter( (item) => item.includes(rolestring))[0]
                role = role.replace(rolestring, '')
                formik.setFieldValue('role', role)
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])
    useEffect(() => {
        if (props.mode === 'create' && props.show.state)
            setIsLoading(false)
    }, [props.mode, props.show.state])


    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>User {props.mode === 'create' ? 'hinzufügen' : 'bearbeiten'}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>

                        {props.mode !== 'update' &&
                            <div className='row mb-3'>
                                <div className="col-12">
                                    <label className='col-form-label fw-bold fs-6'>E-Mail</label>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        placeholder='E-Mail'
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.email}</div>
                                        </div>
                                    )}
                                    {formik.touched.email && formik.errors['email_not_me'] && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors['email_not_me']}</div>
                                        </div>
                                    )}
                                    {formik.touched.email && formik.errors['email_exists'] && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors['email_exists']}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 '>Rolle</label>
                            </div>
                        </div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='owner'
                                    id='role_owner'
                                    defaultChecked={formik.values.role === 'owner'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_owner'>
                                    <div className='fw-bolder text-gray-800'>{HotelRoleEnum.owner}</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='admin'
                                    id='role_admin'
                                    defaultChecked={formik.values.role === 'admin'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_admin'>
                                    <div className='fw-bolder text-gray-800'>{HotelRoleEnum.admin}</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='manager'
                                    id='role_manager'
                                    defaultChecked={formik.values.role === 'manager'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_manager'>
                                    <div className='fw-bolder text-gray-800'>{HotelRoleEnum.manager}</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='editor'
                                    id='role_editor'
                                    defaultChecked={formik.values.role === 'editor'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_editor'>
                                    <div className='fw-bolder text-gray-800'>{HotelRoleEnum.editor}</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='reader'
                                    id='role_reader'
                                    defaultChecked={formik.values.role === 'reader'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_reader'>
                                    <div className='fw-bolder text-gray-800'>{HotelRoleEnum.reader}</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('role')}
                                    name='role'
                                    type='radio'
                                    value='webmaster'
                                    id='role_webmaster'
                                    defaultChecked={formik.values.role === 'webmaster'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='role_webmaster'>
                                    <div className='fw-bolder text-gray-800'>{HotelRoleEnum.webmaster}</div>
                                </label>
                            </div>
                        </div>


                    </div>

                    <div className='modal-footer gap-2 justify-content-end'>

                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-success': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Speichern</span>
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }

        </Modal >
    )
}

export { CUUserModal }
