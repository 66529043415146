import { useEffect, useMemo, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useHinodeMapperContext } from '../../providers/HinodeMapperContextProvider'
import clsx from 'clsx';
import { KTIcon } from '../../../_metronic/helpers';
import { toast } from 'react-hot-toast';

import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HinodeMapper = (props) => {

  const [searchStringEp, setSearchStringEp] = useState<string>('');
  const [searchStringHotel, setSearchStringHotel] = useState<string>('');
  const [searchSelect, setSearchSelect] = useState<number>(0);
  const [eps, setEps] = useState<any[]>([]);
  const { endpoints, hinodes, isLoading, updateEndpoints } = useHinodeMapperContext();


  const filter = (strEp, strHotel, select) => {
    strEp = strEp.toLowerCase();
    strHotel = strHotel.toLowerCase();
    const e = endpoints.filter((d) => {
      let byStringEp = d.name.toLowerCase().includes(strEp) || d.uniqueId.toLowerCase().includes(strEp)
      if (strEp.length < 3)
        byStringEp = true
      let byStringHotel = d.hotel.name.toLowerCase().includes(strHotel) || d.hotel.hotelId.toLowerCase().includes(strHotel)
      if (strHotel.length < 3)
        byStringHotel = true

      let bySelect = true
      if (parseInt(select) !== 0)
        bySelect = d.hinode === null ? false : d.hinode.id === parseInt(select)

      return byStringHotel && byStringEp && bySelect
    })
    setEps(e)
  }
  useMemo(() => filter(searchStringEp, searchStringHotel, searchSelect), [searchStringEp, searchStringHotel, searchSelect]);

  const handleSearchStringEp = (e) => {
    const { value } = e.target;
    setSearchStringEp(value)
  }
  const handleSearchStringHotel = (e) => {
    const { value } = e.target;
    setSearchStringHotel(value)
  }
  const handleSearchSelect = (e) => {
    const { value } = e.target;
    setSearchSelect(value)
  }
  const updateItem = (item, e) => {
    updateEndpoints([item], e.target.value).then((d) => toast.success(d.message)).catch((e) => toast.error("Ein Fehler ist aufgetreten."));
  }

  useEffect(() => { setEps(endpoints) }, [endpoints])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Hinode Mapper
      </PageTitle>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 py-6 px-6">
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                onSubmit={() => { }}
              >
                <div className='row'>
                  <div className="col-lg-4">
                    <input
                      placeholder='Endpoints filtern'
                      type='text'
                      name='search'
                      className={clsx('form-control bg-transparent')}
                      onChange={(e) => handleSearchStringEp(e)}
                    />
                  </div>
                  <div className="col-lg-4">
                    <input
                      placeholder='Hotels filtern'
                      type='text'
                      name='search'
                      className={clsx('form-control bg-transparent')}
                      onChange={(e) => handleSearchStringHotel(e)}
                    />
                  </div>
                  <div className="col-lg-4">
                    <select
                      className='form-select form-select-solid form-select-lg fw-bold'
                      onChange={(e) => handleSearchSelect(e)}
                    >
                      <option value="0">Alle Hindoes</option>
                      {hinodes.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body px-7 pt-0">
              <div className="separator separator-dashed my-0"></div>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='col-4'>
                      <div className='d-flex align-items-center'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid w-40px'>
                          {/*
                          <input
                            className='form-check-input'
                            type='checkbox'
                          />
                        */}
                        </div>
                        Endpoint
                      </div>
                    </th>
                    <th className='col-4'>Hotel</th>
                    <th className='col-4'>Hinode</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>

                  {!isLoading && eps.map((item, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid w-40px'>
                            {/*
                            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                          */}
                          </div>
                          <div>
                            <div className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.name}
                            </div>
                            <div className='badge badge-sm bg-gray-200 text-gray-800 fw-bold my-0 cursor-pointer'>
                              {item.uniqueId}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <OverlayTrigger
                            placement='left'
                            overlay={<Tooltip>Zum Hotel navigieren</Tooltip>}
                          >
                            <Link to={`/h/${item.hotel.hotelId}`}>
                              <div>
                                <div className='text-dark fw-bold fs-6'>
                                  {item.hotel.name}
                                </div>
                                <div className='badge badge-sm bg-gray-200 text-gray-800 fw-bold my-0 cursor-pointer'>
                                  {item.hotel.hotelId}
                                </div>
                              </div>
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <select
                            className='form-select form-select-solid form-select-sm fw-bold'
                            onChange={(e) => updateItem(item, e)}
                            value={item.hinode !== null ? item.hinode.id : 0}
                          >
                            <option value="0">-- nicht gewählt --</option>
                            {hinodes.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                          </select>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export { HinodeMapper }
