/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

//import {useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
//import {AsideTabs} from './AsideTabs'
import {AsideFooter} from './AsideFooter'
//import {TabsBase} from './Tabs/_TabsBase'
import { AsideNav } from './AsideNav'
import { useAsideSecondary } from '../../../../app/providers/AsideSecondaryProvider'

const AsideDefault = () => {
  const {config} = useLayout()
  const {classes} = useLayout()
  //const [link, setLink] = useState<string>('')

  const asideSecondary = useAsideSecondary()
  return (
    <div
      id='kt_aside'
      className={clsx('aside aside-extended', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='false'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Primary */}
      <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
        {/* begin::Logo */}
        <div
          className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10 pt-8'
          id='kt_aside_logo'
        >
          <Link to='/'>
            <img src={toAbsoluteUrl('/media/logos/logo.svg')} alt='logo' className='h-50px' />
            <div className='d-flex flex-column align-items-center'>
              <span className='badge badge-sm bg-gray-500 text-white fw-bold my-0 cursor-pointer mt-6'>BETA</span>
            </div>
          </Link>
        </div>
        {/* end::Logo */}
        {/* begin::Nav */}
        <div
          className='aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0'
          id='kt_aside_nav'
        >
          <AsideNav />
        </div>
        {/* end::Nav */}
        <AsideFooter />
      </div>
      {/* end::Primary */}
      {config.aside.secondaryDisplay && (
        <>
          {/* begin::Secondary */}
          <div className='aside-secondary d-flex flex-row-fluid'>
            {/* begin::Workspace */}
            <div className='aside-workspace my-5 p-5' id='kt_aside_wordspace'>
              
              {asideSecondary.content}
              
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}
          {/* begin::Aside Toggle */}
          <div
            id='kt_aside_toggle'
            className={clsx(
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
              classes.asideToggle.join(' ')
            )}
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            style={{marginBottom: '1.35rem'}}
          >
            <KTIcon iconName='arrow-left' className='fs-2 rotate-180' />
          </div>
          {/* end::Aside Toggle */}
        </>
      )}
    </div>
  )
}

export {AsideDefault}
