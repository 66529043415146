enum HotelFieldNamesEnum {
    name = "Hotel Name",
    address = "Adresse",
    zip = "PLZ",
    city = "Ort",
    country = "Land",
    phone = "Telefon",
    email = "E-Mail",
    vat_id = "Steuer Nr.",
    vat_uid = "UID",
    crn_id = "Firmenbuch Nr.",
    crn_court = "Firmenbuch Gericht",
    iban = "IBAN",
    bic = "BIC",
    lat = "Latitude",
    lng = "Longitude",
}
export default HotelFieldNamesEnum;

