/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ID, KTIcon } from '../../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { HinodesService } from '../../../../../../services/hinodes/_request';

type Props = {
    mode: string
    show: any
    item?: ID
    resetitem: any
    refresh: any
}

const initialValues = {
    name: '',
    env: 'dev',
    active: true
}

const CUHinodesModal: React.FC<Props> = (props) => {

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { createHinode, getById, updateHinode } = HinodesService()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Bitte einen Name eingeben.'),
        env: Yup.string().required('Bitte einen Environment Typ wählen.').oneOf(['prod', 'dev']),
    }).shape({});

    const close = () => {
        props.show.set(false)
        setTimeout(() => {
            props.resetitem()
            props.refresh()
            formik.resetForm()
            setIsLoading(true)
            setIsSending(false)
        }, 200);
    }

    const formikToast = (message, ok = true) => {
        if (ok) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        close()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isSending)
                return
            setIsSending(true)
            if (props.mode === 'create') {
                createHinode(values).then((d) => {
                    formikToast('Hinode erfolgreich gespeichert.')
                }).catch((d) => {
                    formikToast('Es ist ein Fehler aufgetreten.', false)
                })
                return
            }
            if (props.mode === 'update') {
                updateHinode(props.item, values).then((d) => {
                    formikToast('Hinode erfolgreich gespeichert.')
                }).catch((d) => {
                    formikToast('Es ist ein Fehler aufgetreten.', false)
                })
                return
            }

            formikToast('Es ist ein Fehler aufgetreten.', false)
        },
    })

    useEffect(() => {
        if (props.mode === 'update' && props.item !== undefined && props.item !== null) {
            getById(props.item).then((data) => {
                formik.setFieldValue('name', data.name)
                formik.setFieldValue('env', data.env)
                formik.setFieldValue('active', data.active === 1 ? true : false)
                setIsLoading(false)
                setTimeout(() => formik.validateForm(), 200);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])
    useEffect(() => {
        if (props.mode === 'create' && props.show.state)
            setIsLoading(false)
    }, [props.mode, props.show.state])


    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={props.show.state}
            onHide={() => close()}
        >
            <div className='modal-header'>
                <h2 className='mb-0'>Hinode {props.mode === 'create' ? 'erstellen' : 'bearbeiten'}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                    <KTIcon className='fs-1' iconName='cross-circle' />
                </div>
                {/* end::Close */}
            </div>
            {!isLoading ?
                <form onSubmit={formik.handleSubmit}>
                    <div className='modal-body'>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 pt-0'>Name</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className="col-12">
                                <label className='col-form-label fw-bold fs-6 '>Environment</label>
                            </div>
                        </div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('env')}
                                    name='env'
                                    type='radio'
                                    value='dev'
                                    id='env_dev'
                                    defaultChecked={formik.values.env === 'dev'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='env_dev'>
                                    <div className='fw-bolder text-gray-800'>Development</div>
                                </label>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className='d-flex fv-row'>
                            <div className='form-check form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input me-3 cursor-pointer'
                                    {...formik.getFieldProps('env')}
                                    name='env'
                                    type='radio'
                                    value='prod'
                                    id='env_prod'
                                    defaultChecked={formik.values.env === 'prod'}
                                />
                                <label className='form-check-label cursor-pointer' htmlFor='env_prod'>
                                    <div className='fw-bolder text-gray-800'>Production</div>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className='modal-footer gap-2 justify-content-between'>
                        <div className='form-check form-switch form-switch-sm form-check-success form-check-solid'>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={formik.values.active}
                                {...formik.getFieldProps('active')}
                            />
                            <label className='form-check-label'>{formik.values.active ? 'Aktiv' : 'Inaktiv'}</label>
                        </div>
                        <div className="buttonHolder d-flex gap-6">
                            <span
                                className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                onClick={() => close()}
                            >
                                <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                <span className='d-none d-md-inline'>Abbrechen</span>
                            </span>
                            <button
                                className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                    'btn-success': formik.isValid && !isSending
                                })}

                                disabled={!formik.isValid || isSending}
                                type="submit"
                            >
                                {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                    <>
                                        <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                        <span className='d-none d-md-inline'>Speichern</span>
                                    </>
                                }
                            </button>
                        </div>

                    </div>

                </form>
                :
                <div className="d-flex justify-content-center py-10">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }

        </Modal >
    )
}

export { CUHinodesModal }
