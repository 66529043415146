/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useHotelContext } from '../../../providers/HotelContextProvider'
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { EndpointService } from '../../../services/endpoints/_request';
import clsx from 'clsx';


const HotelInfoEndpointsWidget: React.FC<any> = (props) => {
    const [endpoints, setEndpoints] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const { getByHotel } = EndpointService();
    const hotel = useHotelContext();

    useEffect(() => {
        if (hotel.state.hotelId === undefined)
            return
        getByHotel(hotel.state.hotelId).then((d) => {
            setTimeout(() => {
                setEndpoints(d)
                setLoading(false)
            }, 200);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel])

    return (
        <div className={`card `}>
            {/* begin::Body */}
            <div className='card-body pb-0'>
                {/* begin::Header */}
                {(props.noheader === undefined || props.noheader === false) &&
                    <div className='d-flex justify-content-between p-0 mb-5'>


                        <div className='d-flex flex-column'>
                            <span className='text-gray-800 fs-6 fw-bold mb-2'>
                                Importer Endpoints
                            </span>
                            <div className='d-flex flex-row align-items-center'>
                                <div className=''>Anzahl:&nbsp;</div>
                                <div className='badge bg-gray-200 text-gray-800 fw-bold my-0'>{endpoints.length}</div>
                            </div>

                        </div>


                        {/* begin::Menu
                    <div className='my-0'>
                        <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='category' className='fs-2' />
                        </button>
                    </div>
                    {/* end::Menu */}
                    </div>
                }

                {loading &&
                    <div className="d-flex justify-content-center mb-10">
                        <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                    </div>
                }

                {/* end::Header */}
                {endpoints.length > 0 ? <>
                    {(props.noheader === undefined || props.noheader === false) &&
                        <div className='separator mb-6'></div>
                    }
                    <div className="table-responsive mb-6">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className="" style={{ width: '4px' }}></th>
                                    <th className="min-w-150px py-2">Name</th>
                                    <th className="min-w-150px py-2">Typ</th>
                                    <th className="min-w-100px py-2">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {endpoints.map((item) =>
                                    <tr key={item.id}>
                                        <td>
                                            <div className={clsx('bullet d-block bullet-vertical h-60px', {
                                                "bg-success": item.enabled,
                                                "bg-danger": !item.enabled
                                            })}></div>
                                        </td>
                                        <td className="py-4">
                                            <div className='d-flex flex-column'>
                                                <span className='text-gray-800 fs-6 fw-bold mb-2'>
                                                    {item.name}
                                                </span>
                                                <div className='d-flex flex-row align-items-center'>
                                                    <CopyToClipboard text={item.uniqueId}
                                                        onCopy={() => toast.success('Endpoint ID in Zwischenablage kopiert')}>
                                                        <div className='badge badge-sm bg-gray-200 text-gray-800 fw-bold my-0 cursor-pointer'>{item.uniqueId}</div>
                                                    </CopyToClipboard>
                                                </div>
                                                {/* end::Info */}
                                            </div>
                                        </td>
                                        <td className='py-4'>{item.typeReadable}</td>
                                        <td className='py-4'><div className={clsx('badge badge fw-bold mx-0 my-0', {
                                            "badge-light-primary text-success": item.enabled,
                                            "badge-light-danger text-danger": !item.enabled
                                        })}>{item.enabled ? 'Aktiv' : 'Inaktiv'}</div></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                </>
                    :
                    <div className='mb-8'></div>
                }

            </div>
            {/* end::Body */}
        </div>
    )
}

export { HotelInfoEndpointsWidget }
