import { HeaderGroup, TableHeaderProps, useTable } from "react-table";


export interface TableHeaderPropsExtended extends TableHeaderProps {
    className?: string,
}
export interface HeaderGroupExtended extends HeaderGroup {
    classNameHeader?: string,
    classNameColumn?: string
}

export default function AdminHinodesTable({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })
    const getHeaderProps = (column: HeaderGroupExtended) => {
        const props = column.getHeaderProps() as TableHeaderPropsExtended;
        props.className = column.classNameHeader;
        return props;
    }

    return (
        <div className='table-responsive'>
            <table id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className='fw-bold text-muted'>
                            {headerGroup.headers.map(column => {
                                return <th {...getHeaderProps(column)}>{column.render('Header')}</th>
                            }
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}