import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
//import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { HotelContextProvider } from '../providers/HotelContextProvider'
import { HotelOverview } from '../pages/hotel/HotelOverview'
import { HotelEndpointsOverview } from '../pages/hotel/HotelEndpointsOverview'
import { useAuth } from '../modules/auth'
import { AdminContextProvider } from '../providers/AdminContextProvider'
import { AdminUsersOverview } from '../pages/admin/pages/users/AdminUsersOverview'
import { HotelUserList } from '../pages/hotel/HotelUserList'
import { HotelTokenOverview } from '../pages/hotel/HotelTokenOverview'
import { AdminHinodesOverview } from '../pages/admin/pages/users/AdminHinodesOverview'
import { HinodeMapper } from '../pages/hinodemap/HinodeMapper'
import { HinodeMapperContextProvider } from '../providers/HinodeMapperContextProvider'


const PrivateRoutes = () => {
  //const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  //const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  //const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  //const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  //const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  //const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const { isSysadmin } = useAuth()


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={
          <DashboardWrapper />
        } />

        <Route path='/h/:hotelId' element={
          <HotelContextProvider>
            <HotelOverview />
          </HotelContextProvider>
        } />
        <Route path='/h/:hotelId/endpoints' element={
          <HotelContextProvider>
            <HotelEndpointsOverview />
          </HotelContextProvider>
        } />

        <Route path='/h/:hotelId/users' element={
          <HotelContextProvider>
            <HotelUserList />
          </HotelContextProvider>
        } />

        <Route path='/h/:hotelId/tokens' element={
          <HotelContextProvider>
            <HotelTokenOverview />
          </HotelContextProvider>
        } />

        {isSysadmin() ?
          <>
            <Route path='/hinodes' element={
              <HinodeMapperContextProvider>
                <HinodeMapper />
              </HinodeMapperContextProvider>
            } />
            <Route path='/admin' element={
              <AdminContextProvider>
                <AdminUsersOverview />
              </AdminContextProvider>
            } />
            <Route path='/admin/users' element={
              <AdminContextProvider>
                <AdminUsersOverview />
              </AdminContextProvider>
            } />
            <Route path='/admin/hinodes' element={
              <AdminContextProvider>
                <AdminHinodesOverview />
              </AdminContextProvider>
            } />


          </>
          :
          <>
            <Route path='/admin' element={<Navigate to='/error/403' />} />
            <Route path='/admin/*' element={<Navigate to='/error/403' />} />
          </>
        }

        {/*
        
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

/*
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
} */

export { PrivateRoutes }
