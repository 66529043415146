import { useContext, createContext, FC, useEffect } from 'react'
import { WithChildren } from '../../_metronic/helpers'
import { useAsideSecondary } from './AsideSecondaryProvider'
import { AdminSecondaryNav } from '../pages/admin/components/AdminSecondaryNav'
import { useLayout } from '../../_metronic/layout/core'


const AdminContext = createContext<any>({})

const AdminContextProvider: FC<WithChildren> = ({ children }) => {

  const {setContent} = useAsideSecondary();
  const { showAside, hideAside } = useLayout()

  useEffect(() => {
    setContent(<AdminSecondaryNav />)
  },[setContent])
  

  useEffect(() => {
    showAside()
    return () => hideAside()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminContext.Provider value={{ }}>
      {children}
    </AdminContext.Provider>
  )

}

const useAdminContext = () => useContext(AdminContext)
export { AdminContextProvider, useAdminContext }
