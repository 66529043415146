/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTopbarProvider } from '../../../../../providers/TopbarProvider';
import { ID, KTIcon } from '../../../../../../_metronic/helpers';
import { UserService } from '../../../../../services/users/_request';
import AdminUserTable from '../components/AdminUserTable';
import clsx from 'clsx';
import SystemRoleEnum from '../../../../../enum/SystemRoleEnum';
import { CUUserModal } from '../modals/users/CUUserModal';
import { DeleteUserModal } from '../modals/users/DeleteUserModal';

const AdminUserList: React.FC<any> = (props) => {

    const [loading, setLoading] = useState<boolean>(true)

    const [users, setUsers] = useState<any[]>([])
    const [allUsers, setAllUsers] = useState<any[]>([])

    const { getAll } = UserService();

    const [cumodalshow, setCuModalshow] = useState<boolean>(false)
    const [cumodalmode, setCuModalmode] = useState<string>('create')
    const [cumodalitem, setCuModalitem] = useState<ID>(null)

    const [delmodalshow, setDelModalshow] = useState<boolean>(false)
    const [delmodalitem, setDeleteModalitem] = useState<ID>(null)

    const topbar = useTopbarProvider();

    const columns = [
        {
            Header: "Vorname",
            accessor: "firstname",
            classNameHeader: 'w-400px',
        },
        {
            Header: "Nachname",
            accessor: "lastname",
            classNameHeader: 'w-400px',
        },
        {
            Header: "E-Mail",
            accessor: "email",
            classNameHeader: 'w-400px',
        }, {
            Header: "User-Rolle",
            accessor: "system_role",
            classNameHeader: 'w-400px',
            Cell: ({ cell }) => SystemRoleEnum[cell.value]
        },
        {
            Header: "",
            accessor: "actions",

            classNameHeader: 'w-140px',
            classNameColumn: 'bg-info',

            Cell: ({ cell }) => {
                const item = cell.row.original
                const el = <div className="d-flex justify-content-end gap-2">
                    <div className="btn btn-sm btn-icon btn-light-success" onClick={() => updateModal(item.id)}>
                        <KTIcon iconName='pencil' className='fs-6' />
                    </div>
                    <div className="btn btn-sm btn-icon btn-light-danger" onClick={() => deleteModal(item.id)}>
                        <KTIcon iconName='trash' className='fs-6' />
                    </div>
                </div>

                return el
            }
        },
    ]

    const handleChange = (e) => {
        let { value } = e.target
        value = value.toLowerCase()
        if (value.length >= 2) {
            setUsers(allUsers.filter((d) => d.name.toLowerCase().includes(value) || d.email.toLowerCase().includes(value)))
        } else {
            setUsers(allUsers)
        }
    }

    const createModal = () => {
        setCuModalshow(true)
        setCuModalmode('create')
    }
    const updateModal = (item) => {
        setCuModalshow(true)
        setCuModalmode('update')
        setCuModalitem(item)

    }
    const deleteModal = (item) => {
        setDelModalshow(true)
        setDeleteModalitem(item)
    }
    const resetitem = () => {
        setCuModalitem(null)
        setDeleteModalitem(null)
    }
    const refresh = () => {
        getAll().then((d) => {
            setTimeout(() => {
                d = d.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                setUsers(d)
                setAllUsers(d)
                setLoading(false)
            }, 500);
        })
    }

    const setTopbarContent = () => {
        topbar.setContent(
            <div className='d-flex ms-3'>
                <div
                    className='btn  btn-flex flex-center btn-primary w-40px w-md-auto h-40px px-0 px-md-6'
                    onClick={() => createModal()}
                >
                    <KTIcon iconName='plus-square' className='fs-2 text-inverse me-0 me-md-2' />
                    <span className='d-none d-md-inline'>User erstellen</span>
                </div>
            </div>
        )
    }
    const nodata = () => {
        return <div className='card card-dashed bg-transparent'>
            <div className="card-body d-flex flex-column  align-items-center justify-content-center py-10">
                <KTIcon iconName='question-2' className='fs-5x text-inverse mb-6' />
                <div className='text-center fs-2 text-muted mb-4'>0 User</div>
                <div className='text-center text-muted'>Aktuell sind keine User außer dir vorhanden.<br /> Du kannst neue User über den Button oben rechts erstellen.</div>
            </div>
        </div>
    }
    useEffect(() => {
        setTopbarContent()

        refresh()

        return () => topbar.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!cumodalshow)
            setCuModalitem(null)
    }, [cumodalshow])

    return (
        <>
            {loading &&
                <div className="d-flex justify-content-center py-2">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }
            {(!loading && allUsers.length > 0) &&
                <div className="row g-8">

                    <div className='col-lg-12'>
                        <div className="card">
                            <div className="card-header py-6 px-6 d-flex">
                                <input
                                    placeholder='Suche'
                                    type='text'
                                    name='search'
                                    className={clsx('form-control bg-transparent')}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="card-body pt-4">

                                <div className='table-responsive'>
                                    <AdminUserTable columns={columns} data={users} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
            {(!loading && allUsers.length <= 0) && nodata()}

            <CUUserModal mode={cumodalmode} refresh={refresh} show={{ state: cumodalshow, set: setCuModalshow }} item={cumodalitem} resetitem={resetitem} />
            <DeleteUserModal show={{ state: delmodalshow, set: setDelModalshow }} refresh={refresh} item={delmodalitem} resetitem={resetitem} />

        </>
    )
}

export { AdminUserList }
