import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { HotelService } from '../../services/hotel/_request'
import { Link } from 'react-router-dom'
import { CUHotelModal } from '../hotel/components/modals/hotel/CUHotelModal'
import { useTopbarProvider } from '../../providers/TopbarProvider'
import { KTIcon } from '../../../_metronic/helpers'

const DashboardComponent: FC = () => {
  const hotelService = HotelService();
  const [allHotels, setAllHotels] = useState<any[]>([])
  const [filteredHotels, setFilteredHotels] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')

  const topbar = useTopbarProvider();
  const [cumodalshow, setCuModalshow] = useState<boolean>(false)

  const createModal = () => {
    setCuModalshow(true)
  }
  const setTopbarContent = () => {
    topbar.setContent(
      <div className='d-flex ms-3'>
        <div
          className='btn  btn-flex flex-center btn-primary w-40px w-md-auto h-40px px-0 px-md-6'
          onClick={() => createModal()}
        >
          <KTIcon iconName='plus-square' className='fs-2 text-inverse me-0 me-md-2' />
          <span className='d-none d-md-inline'>Hotel erstellen</span>
        </div>
      </div>
    )
  }
  const filter = () => {
    const value = search.toLowerCase()
    if (value.length >= 2) {
      setFilteredHotels(allHotels.filter((d) => d.name.toLowerCase().includes(value) || d.hotelId.toLowerCase().includes(value)))
    } else {
      setFilteredHotels(allHotels)
    }
  }
  const handleChange = (e) => {
    let { value } = e.target
    setSearch(value)
  }
  const refresh = (bymodal = false) => {
    hotelService.getAll().then((d) => {
      setAllHotels(d);
      setFilteredHotels(d)
      if(bymodal)
        filter()
    });
  }
  useEffect(() => {
      filter()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    setTopbarContent()
    refresh()
    return () => {
      topbar.reset()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className={`card`}>
          {/* begin::Body */}
          <div className='card-body py-6 px-3'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              onSubmit={() => { }}
            >
              {/* begin::Form group Firstname */}
              <div className='fv-row'>
                <input
                  placeholder='Hotel Suche'
                  type='text'
                  name='search'
                  className={clsx('form-control bg-transparent')}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {/* end::Form group */}
            </form>
          </div>
        </div>
        {filteredHotels.map((item) =>
          <Link className={`card bg-hover-light`} key={item.id} to={'/h/' + item.hotelId}>
            {/* begin::Body */}
            <div className='card-body py-6 px-3 cursor-pointer'>
              <div className="card-header border-0 p-0" style={{ minHeight: 0 }}>
                <h3 className="card-title align-items-start flex-column m-0 p-0" >
                  <span className="card-label fw-bold text-dark">{item.name}</span>
                  <span className="text-muted mt-1 fw-semibold fs-7">ID: {item.hotelId}</span></h3>
              </div>

            </div>
          </Link>
        )}
      </div>
      <CUHotelModal mode="create" show={{ state: cumodalshow, set: setCuModalshow }} item={null} refresh={refresh}/>

    </>
  )
}

export { DashboardComponent }
