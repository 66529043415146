/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { ID, KTIcon } from '../../../../_metronic/helpers'
import { useHotelContext } from '../../../providers/HotelContextProvider'
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import moment from 'moment';
import { useTopbarProvider } from '../../../providers/TopbarProvider';
import { PubleTokenService } from '../../../services/publetokens/_request';
import { CUTokenModal } from '../components/modals/token/CUTokenModal';
import { DeleteTokenModal } from '../components/modals/token/DeleteTokenModal';

const API_URL = process.env.REACT_APP_FRONTEND_API_URL

const HotelTokenWidget: React.FC<any> = (props) => {
    const [tokens, setTokens] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const [cumodalshow, setCuModalshow] = useState<boolean>(false)
    const [cumodalmode, setCuModalmode] = useState<string>('create')
    const [cumodalitem, setCuModalitem] = useState<ID>(null)


    const [delmodalshow, setDelModalshow] = useState<boolean>(false)
    const [delmodalitem, setDelModalitem] = useState<ID>(null)

    const tokenService = PubleTokenService();

    const topbar = useTopbarProvider();
    const hotel = useHotelContext();

    const createModal = () => {
        setCuModalshow(true)
        setCuModalmode('create')
    }
    const updateModal = (item) => {
        setCuModalshow(true)
        setCuModalmode('update')
        setCuModalitem(item)
    }
    const deleteModal = (item) => {
        setDelModalshow(true)
        setDelModalitem(item)
    }
    const resetitem = () => {
        setCuModalitem(null)
        setDelModalitem(null)
    }

    const setTopbarContent = () => {
        topbar.setContent(
            <div className='d-flex ms-3'>
                <div
                    className='btn  btn-flex flex-center btn-primary w-40px w-md-auto h-40px px-0 px-md-6'
                    onClick={() => createModal()}
                >
                    <KTIcon iconName='plus-square' className='fs-2 text-inverse me-0 me-md-2' />
                    <span className='d-none d-md-inline'>Token erstellen</span>
                </div>
            </div>
        )
    }
    useEffect(() => {
        setTopbarContent()
        return () => topbar.reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!cumodalshow)
            setCuModalitem(null)
    }, [cumodalshow])

    useEffect(() => {
        if (hotel.state.hotelId === undefined)
            return
        tokenService.getByHotel(hotel.state.hotelId).then((d) => {
            setTimeout(() => {
                d = d.sort((a, b) => a.name > b.name ? 1 : -1)
                setTokens(d)
                setLoading(false)
            }, 100);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel])

    return (
        <>
            {loading &&
                <div className="d-flex justify-content-center py-2">
                    <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                </div>
            }
            {(!loading && tokens.length > 0) &&
                <div className="row g-8">
                    {tokens.map((item, i) =>
                        <div key={i} className={clsx('col-lg-6', {
                            'opacity-50': !item.active
                        })}>
                            <div className="card">
                                <div className="card-body pt-4">
                                    <div className="card-header p-0 border-0">
                                        <h3 className="card-title align-items-start flex-column col-10">
                                            <div className="card-label fw-bold text-gray-800">
                                                {item.name}
                                            </div>
                                            <div className="mt-1">
                                                <CopyToClipboard text={item.token}
                                                    onCopy={() => toast.success('Token in Zwischenablage kopiert')}>
                                                    <div className='badge badge-sm bg-gray-200 text-gray-800 fw-bold my-0 cursor-pointer'>{item.token}</div>
                                                </CopyToClipboard>
                                            </div>
                                        </h3>
                                        <div className="card-toolbar gap-3">
                                            <div className="btn btn-sm btn-icon btn-light-success" onClick={() => updateModal(item.id)}>
                                                <KTIcon iconName='pencil' className='fs-6' />
                                            </div>
                                            <div className="btn btn-sm btn-icon btn-light-danger" onClick={() => deleteModal(item.id)}>
                                                <KTIcon iconName='trash' className='fs-6' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator separator-dashed mb-3"></div>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <span>Endpoint: {item.endpoint.name}</span>
                                            <br /><span className='badge badge-sm bg-gray-200 text-gray-800 fw-bold my-0 cursor-pointer'>{item.endpoint.uniqueId}</span>
                                        </div>
                                        <div className="col-4">
                                            Zuletzt gesehen <br /><span className='fw-bold'>{item.last_seen === null ? 'Nie' : moment(item.last_seen).format('DD.MM.YYYY HH:mm:ss')}</span>
                                        </div>
                                        <div className="col-2 text-end">
                                            <div className={clsx('badge badge-lg fw-bold mx-0 my-0', {
                                                "badge-light-primary text-success": item.active,
                                                "badge-light-danger text-danger": !item.active
                                            })}>{item.active ? 'Aktiv' : 'Inaktiv'}</div>
                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            {(!loading && tokens.length <= 0) &&
                <div className='card card-dashed bg-transparent'>
                    <div className="card-body d-flex flex-column  align-items-center justify-content-center py-10">
                        <KTIcon iconName='question-2' className='fs-5x text-inverse mb-6' />
                        <div className='text-center fs-2 text-muted mb-4'>0 Token</div>
                        <div className='text-center text-muted'>Aktuell sind keine Token vorhanden.<br /> Du kannst neue Tokens über den Button oben rechts erstellen.</div>
                    </div>
                </div>
            }
            <CUTokenModal mode={cumodalmode} show={{ state: cumodalshow, set: setCuModalshow }} item={cumodalitem} resetitem={resetitem} />
            <DeleteTokenModal show={{ state: delmodalshow, set: setDelModalshow }} item={delmodalitem} resetitem={resetitem} />
        </>
    )
}

export { HotelTokenWidget }
