/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import * as Yup from 'yup';
import { HinodesService } from '../../../../../../services/hinodes/_request';
import HinodeConfigNamesEnum from '../../../../../../enum/HinodeConfigNamesEnum';

type Props = {
    show: any
}

const initialValues = {
    hinodes_db_host: '',
    hinodes_db_port: '',
    hinodes_db_db: '',
    hinodes_db_user: '',
    hinodes_db_pw: '',
    hinodes_minio_endpoint: '',
    hinodes_aws_key_id: '',
    hinodes_aws_key_secret: '',
    hinodes_aws_key_bucket: '',
    hinodes_image_storage_path: '',
    hinodes_image_storage_public_url: '',
    hinodes_telegram_chat_id: '',
    hinodes_telegram_bot_token: '',
    hinodes_db_authdb: '',
}
const DefaultHinodesSettingsModal
    : React.FC<Props> = (props) => {

        const [isSending, setIsSending] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState<boolean>(true);

        //const [item, setItem] = useState<any>(null);

        const { getDefaultSettings, updateDefaultSettings } = HinodesService();

        const close = () => {
            props.show.set(false)
            setTimeout(() => {
                formik.resetForm()
                setIsLoading(true)
                setIsSending(false)
            }, 200);
        }

        const formikToast = (message, ok = true) => {
            if (ok) {
                toast.success(message)
            } else {
                toast.error(message)
            }
            close()
        }

        const formik = useFormik({
            initialValues,
            validationSchema: Yup.object().shape({
                hinodes_db_host: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_db_host']} ausfüllen.`),
                hinodes_db_port: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_db_port']} ausfüllen.`),
                hinodes_db_db: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_db_db']} ausfüllen.`),
                hinodes_db_user: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_db_user']} ausfüllen.`),
                hinodes_db_pw: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_db_pw']} ausfüllen.`),
                hinodes_minio_endpoint: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_minio_endpoint']} ausfüllen.`),
                hinodes_aws_key_id: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_aws_key_id']} ausfüllen.`),
                hinodes_aws_key_secret: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_aws_key_secret']} ausfüllen.`),
                hinodes_aws_key_bucket: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_aws_key_bucket']} ausfüllen.`),
                hinodes_image_storage_path: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_image_storage_path']} ausfüllen.`),
                hinodes_image_storage_public_url: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_image_storage_public_url']} ausfüllen.`),
                hinodes_telegram_chat_id: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_telegram_chat_id']} ausfüllen.`),
                hinodes_telegram_bot_token: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_telegram_bot_token']} ausfüllen.`),
                hinodes_db_authdb: Yup.string().required(`Bitte ${HinodeConfigNamesEnum['hinodes_db_authdb']} ausfüllen.`),
            }),
            onSubmit: (values) => {
                if (isSending)
                    return
                updateDefaultSettings(values)
                .then(() => formikToast('Die Einstellungen wurden gespeichert.'))
                .catch((data) => {
                    formikToast('Es ist ein Fehler aufgetreten.', false)
                })
                setIsSending(true)
            },
        })

        useEffect(() => {
            if (props.show.state === true)
                getDefaultSettings().then((data) => {
                    Object.keys(data).forEach((index) => {
                        formik.setFieldValue(index, data[index])
                    })
                    setIsLoading(false)
                })
            setTimeout(() => formik.validateForm(), 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.show.state])
        return (
            <Modal
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-600px'
                show={props.show.state}
                onHide={() => close()}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>Hinode Standard Konfiguration</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => close()}>
                        <KTIcon className='fs-1' iconName='cross-circle' />
                    </div>
                    {/* end::Close */}
                </div>
                {!isLoading ?
                    <form onSubmit={formik.handleSubmit}>
                        <div className='modal-body'>
                            <div className='row mb-3'>
                                <div className="col-12">
                                    <div className="alert alert-danger fs-5"><b>Achtung:</b> Bitte sei hier besonders vorsichtig. Einstellungen können nicht zurückgerollt werden, da sie nicht versioniert sind. Dementsprechend können Änderungen dazu führen, dass keine Daten mehr importiert werden, oder Bildpfade nicht mehr stimmen.</div>
                                </div>
                            </div>
                            {Object.keys(HinodeConfigNamesEnum).map((item, index) =>
                                <div className='row mb-3' key={index}>
                                    <div className="col-12">
                                        <label className='col-form-label fw-bold fs-6 pt-0mb-10'> {HinodeConfigNamesEnum[item]}</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder={HinodeConfigNamesEnum[item]}
                                            {...formik.getFieldProps(item)}
                                        />
                                        {formik.touched[item] && formik.errors[item] && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors[item]}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='modal-footer gap-2'>
                            <div className="buttonHolder d-flex gap-6">
                                <span
                                    className='btn  btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6'
                                    onClick={() => close()}
                                >
                                    <KTIcon iconName='cross-square' className='fs-2 text-inverse me-0 me-md-2' />
                                    <span className='d-none d-md-inline'>Abbrechen</span>
                                </span>
                                <button
                                    className={clsx('btn btn-flex flex-center btn-secondary w-40px w-md-auto h-40px px-0 px-md-6', {
                                        'btn-success': formik.isValid && !isSending
                                    })}

                                    disabled={!formik.isValid || isSending}
                                    type="submit"
                                >
                                    {isSending ? <span className='spinner-border spinner-border-sm text-dark align-middle'></span> :
                                        <>
                                            <KTIcon iconName='check-square' className='fs-2 text-inverse me-0 me-md-2' />
                                            <span className='d-none d-md-inline'>Speichern</span>
                                        </>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="d-flex justify-content-center py-10">
                        <span className='spinner-border spinner-border-xl text-primary align-middle'></span>
                    </div>
                }
            </Modal >
        )
    }

export {
    DefaultHinodesSettingsModal
}
