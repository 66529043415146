import { useContext, createContext, FC, useEffect, useState, useMemo } from 'react'
import { WithChildren } from '../../_metronic/helpers'
import { useAsideSecondary } from './AsideSecondaryProvider'

import { useLayout } from '../../_metronic/layout/core'
import { EndpointService } from '../services/endpoints/_request'
import { HinodesService } from '../services/hinodes/_request'


const HinodeMapperContext = createContext<any>({})

const HinodeMapperContextProvider: FC<WithChildren> = ({ children }) => {

  const { setContent } = useAsideSecondary();
  const { showAside, hideAside } = useLayout()

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selected, setSelected] = useState<any[]>([]);
  const [changed, setChanged] = useState<any[]>([]);

  const [endpoints, setEndpoints] = useState<any[]>([]);
  const [hinodes, setHinodes] = useState<any[]>([]);


  const endpointService = EndpointService();
  const hinodeService = HinodesService();

  const updateEndpoints = (items, h) => {
    const itemIds = items.map((i) => i.id)
    return hinodeService.mapEndpointsToHinode(itemIds, h).then((d) => {
      fetchData();
      return d;
    });
  }
  //const endpointsMemo = useMemo(() => updateEndpoints(1), [changed]);

  const checkLoading = (e, h) => {
    if (e.length !== 0 && h.length !== 0)
      setIsLoading(false)
  }
  useMemo(() => checkLoading(endpoints, hinodes), [endpoints, hinodes]);


  const fetchData = () => {
    endpointService.getAll().then((d) => setEndpoints(d))
    hinodeService.getAll().then((d) => setHinodes(d))
  }

  useEffect(() => {
    setContent()
  }, [setContent])

  useEffect(() => {
    //showAside()
    hideAside()
    fetchData()
    return () => hideAside()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HinodeMapperContext.Provider value={{ endpoints, updateEndpoints, hinodes, isLoading }}>
      {children}
    </HinodeMapperContext.Provider>
  )

}

const useHinodeMapperContext = () => useContext(HinodeMapperContext)
export { HinodeMapperContextProvider, useHinodeMapperContext }
