

enum HinodeConfigNamesEnum {
    hinodes_db_host =  'Datenbank Host',
    hinodes_db_port =  'Datenbank Port',
    hinodes_db_db =  'Datenbank Name',
    hinodes_db_user =  'Datenbank Benutzer',
    hinodes_db_pw =  'Datenbank Passwort',
    hinodes_db_authdb =  'Datenbank Auth',
    hinodes_minio_endpoint =  'S3 Storage Endpoint',
    hinodes_aws_key_id =  'S3 Key ID',
    hinodes_aws_key_secret =  'S3 Key Secret',
    hinodes_aws_key_bucket =  'S3 Bucket',
    hinodes_image_storage_path =  'S3 Bild Pfad',
    hinodes_image_storage_public_url =  'S3 öffentliche URL',
    hinodes_telegram_chat_id =  'Telegram Chat ID',
    hinodes_telegram_bot_token =  'Telegram Chat Token',
}
export default HinodeConfigNamesEnum;