import { FC, useEffect } from 'react'
import { AsideMenuItem } from '../../../../_metronic/layout/components/aside/AsideMenuItem'
import { useLocation } from 'react-router-dom'
import { ToggleComponent } from '../../../../_metronic/assets/ts/components'


const AdminSecondaryNav: FC<any> = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        setTimeout(() => {
            ToggleComponent.reinitialization()
        }, 50)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return (

        <div className='d-flex h-100 flex-column'>
            {/* begin::Wrapper */}

            <div
                id='kt_aside_menu_wrapper'
                className='hover-scroll-overlay-y my-2 my-lg-2 pe-lg-n1'
                data-kt-scroll='true'
                data-kt-scroll-height='auto'
                data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
                data-kt-scroll-wrappers='#kt_aside, #kt_aside_menu'
                data-kt-scroll-offset='5px'
            >
                <div
                    className='menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold'
                    id='#kt_aside_menu'
                    data-kt-menu='true'
                >

                    <div className='menu-item'>
                        <div className='menu-content pt-0 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Navigation</span>
                        </div>
                    </div>
                    {/* 
                    <AsideMenuItem to={`/admin/hotels`} icon='home-3' iconSize='fs-2x' title="Hotels" />
                    */}
                    <AsideMenuItem to={`/admin/users`} title='User verwalten' icon='profile-user' iconSize='fs-2x' hasSub={true} />

                    <AsideMenuItem to={`/admin/hinodes`} title='Hotel Importer Nodes verwalten' icon='data' iconSize='fs-2x' hasSub={true} />


                    {/* 
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
                        </div>
                    </div>
                    */}

                </div>
            </div>
        </div>

    )
}

export { AdminSecondaryNav }
