import { useState, useContext, createContext, FC } from 'react'
import { WithChildren } from '../../_metronic/helpers'

const initialContent: any = null
const AsideSecondary = createContext<any>({})

const AsideSecondaryProvider: FC<WithChildren> = ({ children }) => {
  const [content, setContent] = useState<any>(initialContent)

  const reset = () => {
    setContent(null)
  }
  
  return (
    <AsideSecondary.Provider value={{ reset, content, setContent }}>
      {children}
    </AsideSecondary.Provider>
  )

}

const useAsideSecondary = () => useContext(AsideSecondary)
export { AsideSecondaryProvider, useAsideSecondary }
