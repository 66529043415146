import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`

const UserService = () => {

    const getAll = (query?: string): Promise<any> => {
        return axios
            .get(`${USER_URL}?${query}`)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const getById = (id: ID): Promise<any | undefined> => {
        return axios
            .get(`${USER_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    const getByEmail = (email: string): Promise<any | undefined> => {
        return axios
            .post(`${USER_URL}_mail`, {email: email})
            .then((response: Response<any>) => response.data)
    }
    const createUser = (data: any): Promise<any | undefined> => {
        return axios
            .post(`${USER_URL}`, data)
            .then((response: Response<any>) => response.data)
    }

    const updateUser = (id: ID, data: any): Promise<any | undefined> => {
        return axios
            .put(`${USER_URL}/${id}`, data)
            .then((response: Response<any>) => response.data)
    }
    const updateMe = (data: any): Promise<any | undefined> => {
        return axios
            .post(`${USER_URL}/updateme`, data)
            .then((response: Response<any>) => response.data)
    }
    const deleteUser = (id: ID): Promise<any | undefined> => {
        return axios
            .delete(`${USER_URL}/${id}`)
            .then((response: Response<any>) => response.data)
    }
    return {
        getAll,
        getById,
        createUser,
        updateUser,
        deleteUser,
        getByEmail,
        updateMe
    }
}

export { UserService }