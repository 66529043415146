import { useEffect, useState } from 'react'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'

import { HotelEndpointsWidget } from './pages/HotelEndpointsWidget'
import { useHotelContext } from '../../providers/HotelContextProvider'

const HotelEndpointsOverview = (props) => {
    const { showAside } = useLayout()
    const [cumodalshow, setCuModalshow] = useState<boolean>(false)

    const hotel = useHotelContext();

    const breadCrumbs: Array<PageLink> = [
        {
            title: 'Hotels',
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
        },
        {
            title: hotel.state.name,
            path: `/h/${hotel.state.hotelId}`,
            isSeparator: false,
            isActive: false,
        }
    ]

    useEffect(() => {
        showAside()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageTitle breadcrumbs={breadCrumbs}>
                Endpoints
            </PageTitle>
            <div className="row">
                <div className="col-12">
                    <HotelEndpointsWidget modalshow={{state: cumodalshow, set: setCuModalshow}}/>
                </div>
            </div>
        </>
    )
}

export { HotelEndpointsOverview }
